import React, { useContext } from "react";
import { LangContext, TutorialPhase } from "../App";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { Button, IconButton } from "@mui/material";
import { Navigate } from "react-router-dom";
import tutorialDataset from "../../js/tutorial-dataset";
import langs from "../../../langs";

import "../../styles/ui/TutorialExplanation.scss";

export default function TutorialExplanation({
  tutorialDataset,
  nextPhase,
  prevPhase,
  finishTutorial,
}) {
  const lang = useContext(LangContext);
  const phase = useContext(TutorialPhase);

  try {
    const translatedTutorialDataset =
      tutorialDataset[lang] ?? tutorialDataset.en;

    const isFirstPhase = phase === 0;
    const isLastPhase = phase === translatedTutorialDataset.length - 1;

    const isTutorialActive = phase > -1;
    if (!isTutorialActive) {
      return;
    }

    const { title, explanation } = translatedTutorialDataset[phase];

    return (
      <div className="TutorialExplanation">
        <h3>{title}</h3>
        <p>{explanation}</p>
        <div className="TutorialExplanation__controls">
          <IconButton disabled={isFirstPhase} onClick={prevPhase}>
            <FiChevronLeft />
          </IconButton>
          <IconButton disabled={isLastPhase} onClick={nextPhase}>
            <FiChevronRight />
          </IconButton>
          <Button
            variant="contained"
            style={{ float: "right" }}
            onClick={finishTutorial}
          >
            {langs[lang]?.ui.finish_tutorial ?? langs["en"]?.ui.finish_tutorial}
          </Button>
        </div>
      </div>
    );
  } catch (err) {
    console.log(err);
  }
}

export function TutorialRedirector({ currentPhase, lastClick, forceRoute }) {
  const lang = useContext(LangContext);

  if (forceRoute) {
    return <Navigate to={forceRoute} />;
  }

  try {
    const nextPhase = currentPhase - 1 * lastClick;
    const newPhasePath = tutorialDataset[lang][currentPhase]?.path;
    const prevPhasePath = tutorialDataset[lang][nextPhase]?.path;
    if (!newPhasePath || !prevPhasePath) return;
    if (newPhasePath !== prevPhasePath) {
      return <Navigate to={newPhasePath} />;
    }
  } catch (err) {
    console.error(err);
  }
}
