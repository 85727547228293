import React, { useContext, useEffect, useState } from "react";
import { LangContext } from "../App";

import pwIcon from "../../images/icons/Password.svg";
import openEyeIcon from "../../images/icons/Open Eye.svg";
import closedEyeIcon from "../../images/icons/Close Eye.svg";

import langs from "../../../langs";

import { passwordRegex } from "../../js/context";

// As we need similar styles with regular input, we'll add all of that toghether
import "../../styles/ui/InputText.scss";

export default function InputPassword(props) {
  const [currentValue, setCurrentValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isPwCorrect, setIsPwCorrect] = useState(true);

  const isError = !isPwCorrect && currentValue.length > 0;

  useEffect(
    function () {
      setIsPwCorrect(passwordRegex.test(currentValue));
    },
    [currentValue]
  );

  function updateInputValue(e) {
    setCurrentValue(e.target.value);
    props?.onChange(e);
  }

  const lang = useContext(LangContext);

  const displayErrorMsg =
    isError && !props?.hideErrors
      ? langs[lang]?.login_register?.validation?.password_error
      : "";


  const type = showPassword ? "text" : "password";
  const iconHeightCorrection = showPassword
    ? { transform: `translateY(.2rem)` }
    : {};
  const changeVisibilityIcon = showPassword ? closedEyeIcon : openEyeIcon;
  function changeVisibility() {
    setShowPassword(!showPassword);
  }

  return (
    <div className="input-text-wrapper">
      <label className="input-text-label">{props?.label ?? "Password"}</label>

      <div className="InputPassword">
        <img src={pwIcon} alt="" className="InputPassword__icon" />
        <input
          {...props}
          type={type}
          onChange={updateInputValue}
          className="InputPassword__input"
        />
        <button
          type="button"
          onClick={changeVisibility}
          className="InputPassword__toggle-button"
        >
          <img
            src={changeVisibilityIcon}
            style={iconHeightCorrection}
            alt=""
            className="InputPassword__icon"
          />
        </button>
      </div>
      <span className="input-error">{displayErrorMsg}</span>
    </div>
  );
}
