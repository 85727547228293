import React, { useState, useEffect } from "react";

import "../../styles/app/ChangeColor.scss";

export default function ChangeColor({ onChange, defaultColor, style }) {
  const [color, setColor] = useState(defaultColor);

  useEffect(() => {
    setColor(defaultColor);
  }, [defaultColor])


  function handleChangeColor(e) {
    const color = e.target.value;
    setColor(color);
    onChange(color);
  }

  return;

  return (
    <div className="ChangeColor" style={style}>
      <input
        type="color"
        value={color}
        onChange={handleChangeColor}
        className="ChangeColor__input"
      />
      <div
        className="ChangeColor__sample"
        style={{ backgroundColor: color }}
      ></div>
    </div>
  );
}
