import React from "react";
import { SvgBlob } from "react-svg-blob";
import { getRandomColor, getRandomInt } from "../../js/functions";

import "../../styles/ui/FancyButton.scss";


function generateShapeProps() {
  return {
    size: 100,
    growth: getRandomInt(8, 9),
    edges: getRandomInt(3, 5),
  };
}


export default function FancyButton({ children, onClick, style }) {

  return (
    <button className="FancyButton" onClick={onClick} style={style}>
      {children}
    </button>
  );
}
