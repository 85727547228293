import React, { useContext } from "react";
import FancyButton from "./FancyButton";
import { UserTypeContext } from "../App";
import Centerer from "./Centerer";

export default function ConditionalRenderButton(props) {
  const { onClick, children: inner, rules } = props;
  const userType = useContext(UserTypeContext);

  /* We only render the component if the current 
  userType is set to "true" in the rules prop */
  if (!rules[userType]) return;

  return (
    <Centerer>
      <FancyButton style={{ textAlign: "center" }} onClick={onClick}>
        {inner}
      </FancyButton>
    </Centerer>
  );
}
