export const devEnv = process.env.REACT_APP_ENV === "dev";
export const frontUrl = devEnv
  ? "http://localhost:3001"
  : "https://team.mitali.app";
export const individualUsersFrontUrl = devEnv
  ? "http://localhost:3000"
  : "https://my.mitali.app";
export const apiUrl = devEnv
  ? "http://localhost:3005"
  : "https://api.mitali.app";
export const fallbackDisciplines = [
  "chess",
  "football",
  "running",
  "volleyball",
  "tennis",
];

export const locale = "es-ES";

export const usernameRegex = new RegExp("^[a-zA-Z0-9_.]+$", "i");
export const passwordRegex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{7,}$/;

export const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: "rgba(218, 218, 218, .2)",
    border: 0,
    color: "black",
    borderRadius: 10,
    padding: ".3rem .4rem",
  }),

  menu: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: "rgb(250, 250, 250)",
    borderRadius: 10,
    overflow: "hidden",
    padding: 0,
  }),

  menuList: (baseStyles, state) => ({
    ...baseStyles,
    padding: 0,
  }),

  option: (baseStyles, state) => ({
    ...baseStyles,
    backgroundColor: "transparent",
    overflow: "hidden",
    color: "black",
  }),
};
