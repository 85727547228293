import React from "react";
import SuperEllipse from "react-superellipse";

// We import styles directly from user, because they were too few to put them separately
import "../../styles/app/User.scss";
import { apiUrl, frontUrl } from "../../js/context";

function ProfilePic({ size, userId }) {
  const sizes = ["big", "medium", "small"];
  if (sizes.indexOf(size) === -1) {
    size = "medium";
  }

  function addDefaultSrc({ currentTarget }) {
    currentTarget.onerror = null; // prevents looping
    // The default pic is in front url, because even if backend fails, frontend could keep being alive
    currentTarget.src = frontUrl + "/images/default-profile.png";
  }

  const imagesDirectory = apiUrl + "/images/profile-pic-teams/";
  const imageUrl = imagesDirectory + userId;

  return (
      <img
        className="User__profile-pic"
        src={imageUrl}
        onError={addDefaultSrc}
        alt="Profile pic"
      />
  );
}

export default ProfilePic;
