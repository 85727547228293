import React, { useContext, useEffect, useState } from "react";
import PicUploader from "./PicUploader";
import { UserDataContext } from "../App";
import { apiUrl } from "../../js/context";

export function ParallaxBanner() {
  const userData = useContext(UserDataContext);


  function showDefaultPic(e) {
    const img = e.target;
    img.src = "/images/default-banner-pexels-liger-pham-1108701.jpg";
  }

  const imageUrl = apiUrl + "/images/banners-teams/" + userData._id;

  return (
    <img
      className="banner"
      alt="Banner"
      onError={showDefaultPic}
      src={imageUrl}
    />
  );
}
