import React, { useContext } from "react";
import { apiUrl, individualUsersFrontUrl } from "../../js/context";

import trophyIcon from "../../images/icons/Trophies.svg";
import medalsIcon from "../../images/icons/Medals.svg";

import "../../styles/app/TeamMember.scss";
import langs from "../../../langs";
import { LangContext, UserTypeContext, userTypes } from "../App";
import DisplayProfilePic from "./DisplayProfilePic";
import scoreIcon from "../../images/icons/Score.svg";
import closeIcon from "../../images/icons/Close.svg";
import {
  createAuthHeaders,
  getLocalKey,
  getSession,
  getUserId,
} from "../../js/functions";
import axios from "axios";
import { SmoothCorners } from "react-smooth-corners";

export default function TeamMember(props) {
  const lang = useContext(LangContext);
  const userType = useContext(UserTypeContext);
  const { memberData, isLast } = props;

  async function expelUser(userId) {
    const confirmation = window.confirm("¿Eliminar usuario?");

    if (!confirmation) {
      return;
    }

    const authHeaders = createAuthHeaders(getSession());
    console.log(userId);
    await axios.post(
      apiUrl + "/remove-user-from-team",
      { _id: getUserId(), userToDeleteId: userId },
      { headers: authHeaders }
    );

    window.location.reload();
  }

  const scoreAndRanking = (
    <div className="TeamMember__score-and-ranking">
      <span className="TeamMember__ranking">#{props?.memberPos}</span>
      <span className="TeamMember__score-pill">
        <img
          style={{ marginRight: ".2rem", transform: "translateY(.05rem)" }}
          src={scoreIcon}
          alt=""
        />
        {props?.memberData?.score}
      </span>
    </div>
  );

  const expelUserBtn = (
    <button
      style={{
        position: "absolute",
        right: "0",
        backgroundColor: "transparent",
        border: "none",
        top: "50%",
        filter:
          "invert(49%) sepia(27%) saturate(7107%) hue-rotate(334deg) brightness(81%) contrast(106%)",
      }}
      onClick={() => expelUser(props?.memberData?._id)}
    >
      <img src={closeIcon} alt="" />
    </button>
  );

  return (
    <div className="team-member-wrapper">
      <a
        href={`${individualUsersFrontUrl}/shelf/${memberData?.username}`}
        target="_blank"
        rel="noreferrer"
        className={`TeamMember ${!isLast && "TeamMember--not-last"}`}
      >
        {props?.showScore && scoreAndRanking}
        <DisplayProfilePic
          className="TeamMember__profile-pic"
          userData={memberData}
        />

        <div className="TeamMember__data">
          <div className="TeamMember__names">
            <h2 className="TeamMember__full-name">{memberData?.full_name}</h2>
            <span className="TeamMember__big-dot">·</span>
            <h3 className="TeamMember__username">{memberData?.username}</h3>
          </div>
          <h4 className="TeamMember__trophies-count">
            <img
              className="TeamMember__trophies-count-img"
              src={trophyIcon}
              alt=""
            />
            <span className="TeamMember__bold-num">
              {memberData?.trophies?.length}
            </span>{" "}
            {langs[lang]?.profile?.trophies(memberData?.trophies) ??
              langs["es"]?.profile?.trophies(memberData?.trophies)}
          </h4>
          <h4 className="TeamMember__medals-count">
            <img
              className="TeamMember__medals-count-img"
              src={medalsIcon}
              alt=""
            />
            <span className="TeamMember__bold-num">
              {memberData?.competitions?.length}
            </span>{" "}
            {langs[lang]?.members_list?.medals ??
              langs["es"]?.members_list?.medals}
          </h4>
        </div>
      </a>
      {props?.showExpel && userTypes.owner === userType && expelUserBtn}
    </div>
  );
}
