import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import ConditionalRenderButton from "./ui/ConditionalRenderButton";
import Popup from "./ui/Popup";

import { FiLink, FiFacebook, FiTwitter, FiSearch } from "react-icons/fi";
import { BsWhatsapp } from "react-icons/bs";
import User from "./app/User";
import Shelf, { PopupTrophy } from "./app/Shelf";
import Notification from "./ui/Notification";
import { isEmail } from "validator";
import CountrySelect from "./ui/CountrySelect";
import { FiShare2 } from "react-icons/fi";
import { Tooltip } from "react-tooltip";

import {
  fetchUserData,
  getQueryParams,
  copyToClipboard,
  saveSession,
  getSession,
  saveUserId,
  getUserId,
  removeSession,
  removeUserId,
  getTeamNickname,
  saveTeamNickname,
  sleep,
  getLocalKey,
  createAuthHeaders,
  filterNonDisciplineTrophiesAndMedalsFromAllUsers,
} from "../js/functions";
import Checkbox from "./ui/Checkbox.jsx";
import Loader from "./ui/Loader";
import ChooseDisciplines from "./ui/ChooseDisciplines";
import { TailSpin } from "react-loader-spinner";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Link,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import Settings from "./app/Settings";
import Button from "./ui/Button.jsx";
import uuid from "react-uuid";
import ForceRedirect from "./ui/ForceRedirect";
import "../styles/App.scss";
import { useLongPress } from "use-long-press";
import image404 from "../images/not-found1.png";
import imageError from "../images/broken-trophy.png";
import Centerer from "./ui/Centerer";
import { SelectDisciplines } from "./app/Settings";

import langs from "../../langs.js";
import TutorialExplanation, {
  TutorialRedirector,
} from "./ui/TutorialExplanation";

import tutorialDataset from "../js/tutorial-dataset";
import InputPassword from "./ui/InputPassword.jsx";
import ValidateTextField from "./ui/ValidateTextField";
import {
  apiUrl,
  frontUrl,
  individualUsersFrontUrl,
  usernameRegex,
} from "../js/context";
import Cookies from "js-cookie";
import { ParallaxBanner } from "./ui/ParallaxBanner";
import ShelfSwitch from "./app/ShelfSwitch";
import useSWR from "swr";
import Claps from "./app/Claps";
import AskInstallApp from "./ui/AskInstallApp.jsx";
import PWAPrompt from "react-ios-pwa-prompt";
import initReactFastclick from "react-fastclick";

import userIcon from "../images/icons/Username.svg";
import emailIcon from "../images/icons/Email.svg";
import settingsIcon from "../images/icons/Setting.svg";
import shareIcon from "../images/icons/Share.svg";
import infoIcon from "../images/icons/Info.svg";

import logo512 from "../images/logo512.png";
import logo192 from "../images/logo192.png";
import logo from "../images/logo.png";
import InputText from "./ui/InputText.jsx";
import BottomNav from "./app/BottomNav.jsx";
import MembersList from "./app/MembersList.jsx";
import ManualPopup from "./ui/ManualPopup.jsx";
import MembersRanking from "./app/MembersRanking.jsx";
import { SmoothCorners } from "react-smooth-corners";

export const LangContext = createContext(null);
export const ThemeContext = createContext(null);
export const UserTypeContext = createContext(null);
export const TutorialPhase = createContext(0);
export const LoggedUserKey = createContext(null);
export const UserDataContext = createContext(null);

// Avoid delay on mobile clicks
initReactFastclick();

const defaultLang = "es";

// Saving them in an object for a better mantainability
// Also, with that, we have the autocomplete for the props
export const userTypes = {
  owner: "owner",
  visitor: "visitor",
};

function ShareInnerPopup() {
  const lang = useContext(LangContext);
  const [showNotif, setShowNotif] = useState(false);

  useEffect(() => {
    if (!showNotif) return;
    setTimeout(() => setShowNotif(false), 2600);
  }, [showNotif]);

  const notif = showNotif ? (
    <Notification>
      {langs[lang]?.ui?.copied_clipboard ??
        langs[defaultLang]?.ui?.copied_clipboard}
    </Notification>
  ) : (
    ""
  );

  return (
    <>
      {notif}
      <div className="ShareInnerPopup">
        <h2 className="ShareInnerPopup__title">
          {langs[lang]?.popups?.share?.title ??
            langs[defaultLang]?.popups?.share?.title}
        </h2>
        <h3 className="ShareInnerPopup__subtitle">
          {langs[lang]?.popups?.share?.subtitle ??
            langs[defaultLang]?.popups?.share?.subtitle}
        </h3>
        <ul className="ShareInnerPopup__channels">
          <li>
            <Button
              onClick={() => {
                copyToClipboard(window.location.href);
                setShowNotif(true);
              }}
              className="ShareInnerPopup__channel"
              typeStyle="blue"
            >
              <FiLink className="ShareInnerPopup__chan-icon" />
            </Button>
          </li>
          <li>
            <Button className="ShareInnerPopup__channel" typeStyle="blue">
              <a
                target="_blank"
                rel="noreferrer"
                href={`whatsapp://send?${window.location.href}`}
                data-action="share/whatsapp/share"
                class="ShareInnerPopup__link"
              >
                <BsWhatsapp className="ShareInnerPopup__chan-icon" />
              </a>
            </Button>
          </li>
          <li>
            <Button className="ShareInnerPopup__channel" typeStyle="blue">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}%2F&amp;src=sdkpreparse`}
                class="ShareInnerPopup__link"
              >
                <FiFacebook className="ShareInnerPopup__chan-icon" />
              </a>
            </Button>
          </li>

          <li>
            <Button className="ShareInnerPopup__channel" typeStyle="blue">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://www.twitter.com/share?url=${window.location.href}%2F&amp;src=sdkpreparse`}
                class="ShareInnerPopup__link"
              >
                <FiTwitter className="ShareInnerPopup__chan-icon" />
              </a>
            </Button>
          </li>
        </ul>
      </div>
    </>
  );
}

function fetcher(url) {
  return axios.get(url).then((res) => res.data);
}

function ProfileHeader(props) {
  const { userType, handleShare } = props;
  const lang = useContext(LangContext);

  return (
    <nav className="top-nav">
      {userType === userTypes.owner && (
        <Button className="top-nav__button" onClick={handleShare}>
          <img className="icon" src={shareIcon} alt="Compartir" />
        </Button>
      )}
      <h2 className="top-nav__profile-text">
        {langs?.[lang]?.profile?.profile ?? langs?.["es"]?.profile?.profile}
      </h2>
      {userType === userTypes.owner && (
        <Link to="/settings">
          <Button className="top-nav__button">
            <img className="icon" src={settingsIcon} alt="Opciones" />
          </Button>
        </Link>
      )}
    </nav>
  );
}

function ProfileMusts(props) {
  const { clapsNum, setClapsNum, setDisplayPopup, displayPopup, userType } =
    props;

  return (
    <>
      <Claps clapsNum={clapsNum} setClapsNum={setClapsNum} />

      <Popup
        key={uuid()}
        onHide={() => setDisplayPopup(false)}
        defaultDisplay={displayPopup}
      >
        <ShareInnerPopup></ShareInnerPopup>
      </Popup>
      {userType === userTypes.owner && (
        <>
          <AskInstallApp />
          <PWAPrompt
            promptOnVisit={1}
            timesToShow={1}
            permanentlyHideOnDismiss={false}
          />
        </>
      )}
    </>
  );
}

function ShelfSection(props) {
  const lang = useContext(LangContext);

  const {
    userData,
    setShelfToDisplay,
    shelfToDisplay,
    showTrophy,
    setShowingTrophy,
    toggleShowCompetition,
    handleCreateOwn,
  } = props;

  return (
    <>
      {userData?.trophies?.length > 0 && userData.competitions.length > 0 && (
        <ShelfSwitch setShelfToDisplay={setShelfToDisplay} />
      )}
      <Shelf
        shelfToDisplay={shelfToDisplay}
        showTrophy={showTrophy}
        setShowTrophy={setShowingTrophy}
        userData={userData}
        toggleShowCompetition={toggleShowCompetition}
      />
      <Centerer style={{ paddingBottom: "1rem" }}>
        <ConditionalRenderButton
          onClick={handleCreateOwn}
          rules={{ [userTypes.visitor]: true }}
        >
          <a className="link-in-button" href="https://mitali.app/">
            {langs[lang]?.shelf?.create_own_button ??
              langs[defaultLang]?.shelf?.create_own_button}
          </a>
        </ConditionalRenderButton>
      </Centerer>
    </>
  );
}

function ShelfRoute(props) {
  const {
    userData,
    setShelfToDisplay,
    shelfToDisplay,
    showTrophy,
    displayTrophy,
    showingTrophy,
    setDisplayTrophy,
    setShowingTrophy,
    toggleShowCompetition,
    handleCreateOwn,
    nextPhase,
    loadLocalSettings,
    setProfileId,
    setUserData,
  } = props;

  const shelfComp = (
    <>
      <ShelfSection
        userData={userData}
        setShelfToDisplay={setShelfToDisplay}
        shelfToDisplay={shelfToDisplay}
        showTrophy={showTrophy}
        setShowingTrophy={setShowingTrophy}
        toggleShowCompetition={toggleShowCompetition}
        handleCreateOwn={handleCreateOwn}
      />
    </>
  );

  return (
    <ProfileTemplateRoute
      setUserData={setUserData}
      nextPhase={nextPhase}
      loadLocalSettings={loadLocalSettings}
      setProfileId={setProfileId}
      embeddedComponent={shelfComp}
    />
  );
}

function MembersListRoute(props) {
  const lang = useContext(LangContext);
  const [showPoupup, setShowPopup] = useState(false);
  const [inviteCode, setInviteCode] = useState(undefined);
  const inviteLink = individualUsersFrontUrl + "/join-team?code=" + inviteCode;

  const {
    userData,
    setShelfToDisplay,
    shelfToDisplay,
    showTrophy,
    setShowingTrophy,
    toggleShowCompetition,
    handleCreateOwn,
    nextPhase,
    loadLocalSettings,
    setProfileId,
    setUserData,
  } = props;

  const membersComponent = (
    <MembersList
      userData={userData}
      members={userData.members}
      setShelfToDisplay={setShelfToDisplay}
      shelfToDisplay={shelfToDisplay}
      showTrophy={showTrophy}
      setShowingTrophy={setShowingTrophy}
      toggleShowCompetition={toggleShowCompetition}
      handleCreateOwn={handleCreateOwn}
      setShowPopup={setShowPopup}
      setInviteLink={setInviteCode}
    />
  );

  async function handleCopyInviteLink() {
    if (!inviteLink) {
      alert("Ha ocurrido un error");
      return;
    }

    if (navigator.share) {
      const shareData = {
        title: "Mitali",
        text: "¡Te han invitado a unirte a un equipo en Mitali!",
        url: inviteLink,
      };

      await navigator.share(shareData)

    } else {
      copyToClipboard(inviteLink);
      alert("Link copiado al porta papeles");
    }
  }

  return (
    <>
      <ProfileTemplateRoute
        setUserData={setUserData}
        nextPhase={nextPhase}
        loadLocalSettings={loadLocalSettings}
        setProfileId={setProfileId}
        embeddedComponent={membersComponent}
      />
      <ManualPopup setDisplay={setShowPopup} display={showPoupup}>
        <div className="invite-members-popup">
          <h2 className="invite-members-popup__title">
            {langs[lang]?.members_list?.invite_members_popup?.title ??
              langs["es"]?.members_list?.invite_members_popup?.title}
          </h2>
          <h3 className="invite-members-popup__subtitle">
            {langs[lang]?.members_list?.invite_members_popup?.subtitle ??
              langs["es"]?.members_list?.invite_members_popup?.subtitle}
          </h3>

          <InputText value={inviteLink} />

          <Button typeStyle="block" onClick={handleCopyInviteLink}>
            {langs[lang]?.members_list?.invite_members_popup
              ?.copy_link_button ??
              langs["es"]?.members_list?.invite_members_popup?.copy_link_button}
          </Button>
          <h5 className="invite-members-popup__button-subtitle">
            {langs[lang]?.members_list?.invite_members_popup?.button_subtitle ??
              langs["es"]?.members_list?.invite_members_popup?.button_subtitle}
          </h5>
        </div>
      </ManualPopup>
    </>
  );
}

function MembersRankingRoute(props) {
  const lang = useContext(LangContext);
  const [showPoupup, setShowPopup] = useState(false);

  const {
    userData,
    setShelfToDisplay,
    shelfToDisplay,
    showTrophy,
    setShowingTrophy,
    toggleShowCompetition,
    handleCreateOwn,
    nextPhase,
    loadLocalSettings,
    setProfileId,
    setUserData,
  } = props;

  const membersComponent = (
    <>
      <span
        data-tooltip-id="ranking-calc-tooltip"
        data-tooltip-html={
          langs[lang]?.members_ranking?.ranking_calculation_explanation ??
          langs["es"]?.members_ranking?.ranking_calculation_explanation
        }
        data-tooltip-place="bottom"
        style={{
          display: "block",
          opacity: 0.3,
          marginLeft: ".5rem",
          marginBottom: ".5rem",
          fontSize: ".9rem",
        }}
      >
        {langs[lang]?.members_ranking?.ranking_calculation ??
          langs["es"]?.members_ranking?.ranking_calculation}{" "}
        <img
          style={{
            height: "1rem",
            display: "inline-block",
            transform: "translateY(.2rem)",
            aspectRatio: "1/1",
          }}
          alt=""
          src={infoIcon}
        />
      </span>
      <Tooltip
        id="ranking-calc-tooltip"
        opacity={1}
        style={{
          zIndex: 5,
          backgroundColor: "rgb(250, 250, 250)",
          color: "rgba(0, 0, 0, .6)",
          borderRadius: 10,
          padding: ".7rem",
          boxShadow: "0 5px 30px 1px rgba(0, 0, 0, .3)",
        }}
      />

      <MembersRanking
        userData={userData}
        members={userData.members}
        setShelfToDisplay={setShelfToDisplay}
        shelfToDisplay={shelfToDisplay}
        showTrophy={showTrophy}
        setShowingTrophy={setShowingTrophy}
        toggleShowCompetition={toggleShowCompetition}
        handleCreateOwn={handleCreateOwn}
        setShowPopup={setShowPopup}
      />
    </>
  );

  return (
    <ProfileTemplateRoute
      setUserData={setUserData}
      nextPhase={nextPhase}
      loadLocalSettings={loadLocalSettings}
      setProfileId={setProfileId}
      embeddedComponent={membersComponent}
    />
  );
}

function ProfileTemplateRoute({
  nextPhase,
  loadLocalSettings,
  setProfileId,
  setUserData,
  embeddedComponent,
}) {
  const { profileId } = useParams();
  const lang = useContext(LangContext);
  const userType = useContext(UserTypeContext);
  const [displayPopup, setDisplayPopup] = useState(false);
  const loggedUserKey = useContext(LoggedUserKey);


  let {
    data: userData,
    error: loadingError,
    isLoading,
  } = useSWR(`${apiUrl}/team?id=${profileId}`, fetcher);

  if (userData && userData?.members?.length) {
    const teamDisciplines = userData?.disciplines?.map((disc) => disc.name);

    console.log("hola")
    userData.members = filterNonDisciplineTrophiesAndMedalsFromAllUsers(userData.members, teamDisciplines);
  }

  const [clapsNum, setClapsNum] = useState(userData?.claps ?? 0);

  useEffect(
    function () {
      setClapsNum(userData?.claps);
    },
    [userData]
  );

  setProfileId(profileId);

  function handleCreateOwn() { }

  function findTrophyOrCompetitionIndex(
    userData,
    trophyOrComp,
    field = "trophies"
  ) {
    const trophyOrCompDataString = JSON.stringify(trophyOrComp);

    const trophiesOrComps = userData[field];

    const trophyOrCompIndex = trophiesOrComps.findIndex((trophyOrComp) => {
      trophyOrComp = JSON.stringify(trophyOrComp);

      return trophyOrCompDataString === trophyOrComp;
    });

    return trophyOrCompIndex;
  }

  async function handleShare() {
    if (navigator.share) {
      try {
        await navigator.share({
          title:
            langs[lang]?.popups?.share?.title ??
            langs[defaultLang]?.popups?.share?.title,
          text:
            langs[lang]?.popups?.share?.text ??
            langs[defaultLang]?.popups?.share?.text,
          url: window.location.href,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      setDisplayPopup(true);
    }
  }

  useEffect(() => {
    loadLocalSettings();
  }, []);

  if (loadingError) return <Navigate to="/not-found" />;
  if (isLoading) {
    return <Loader />;
  }

  setUserData(userData);

  return (
    <>
      <ProfileMusts
        clapsNum={clapsNum}
        setClapsNum={setClapsNum}
        setDisplayPopup={setDisplayPopup}
        displayPopup={displayPopup}
        userType={userType}
      />

      <div className="parallax-effect-banner">
        <ParallaxBanner />
        <ProfileHeader userType={userType} handleShare={handleShare} />
        <section className="shelf-route-wrapper">
          <div className="shelf-route-content-wrapper">
            <User
              nextPhase={nextPhase}
              clapsNum={clapsNum}
              userData={userData}
            />
            {embeddedComponent}
          </div>
        </section>
      </div>
      <BottomNav />
    </>
  );
}

function SettingsRoute({
  setUserData,
  optionsState,
  nextPhase,
  startTutorial,
  currentPhase,
  handleLogout,
  loadLocalSettings,
}) {
  const userData = useContext(UserDataContext);
  const profilePicState = useState(undefined);
  //const [profilePic, setProfilePic] = profilePicState;

  useEffect(() => {
    loadLocalSettings();
  }, []);

  return (
    <>
      <Settings
        nextPhase={nextPhase}
        profilePicState={profilePicState}
        userData={userData}
        optionsState={optionsState}
        startTutorial={startTutorial}
        currentPhase={currentPhase}
        handleLogout={handleLogout}
      />
    </>
  );
}

function TrophiesCarrousel() {
  const trophiesArray = [
    `${frontUrl}/images/trophies/1-gold.png`,
    `${frontUrl}/images/trophies/2-gold.png`,
    `${frontUrl}/images/trophies/3-gold.png`,
    `${frontUrl}/images/trophies/4-gold.png`,
    `${frontUrl}/images/trophies/5-gold.png`,
  ];

  return (
    <div className="TrophiesCarrousel">
      <FiSearch className="TrophiesCarrousel__magnifier" />
      <div className="TrophiesCarrousel__trophies-list">
        {Array.from(Array(8).keys()).map((list) =>
          trophiesArray.map((trophy) => (
            <img
              className="TrophiesCarrousel__trophy"
              src={trophy}
              alt="Trophy"
            />
          ))
        )}
      </div>
      <div className="TrophiesCarrousel__till"></div>
    </div>
  );
}

function ServerError() {
  const params = getQueryParams();
  const defaultUrl = "https://mitali.app";
  const backUrl = params.getAll("back").length
    ? params.getAll("back")
    : defaultUrl;

  return (
    <div className="server_error">
      <h1 className="server_error__title">
        Something went wrong <span style={{ whiteSpace: "nowrap" }}>:(</span>
      </h1>
      <h2 className="server_error__description">
        We don't know what happened. Please try again. If the problem still
        exists, please contact us.
      </h2>
      <img className="server_error__img" alt="Broken Trophy" src={imageError} />
      <Centerer style={{ marginTop: "2rem" }}>
        <Button
          onClick={() => (window.location.href = backUrl)}
          variant="contained"
        >
          Go back
        </Button>
      </Centerer>
    </div>
  );
}

function SendResetPasswordLink() {
  const lang = useContext(LangContext);

  const [email, setEmail] = useState();

  function setValue(e) {
    setEmail(e.target.value);
  }

  async function sendEmail() {
    try {
      const { status } = await axios.post(
        apiUrl + "/send-reset-password-link-team",
        { email }
      );

      // Should always access into this if, but just in case, we make sure that the operation has been completed successfully
      if (status === 200) {
        alert(
          "Se ha enviado correctamente el email. Abre tu correo electrónico."
        );
      }
    } catch (err) {
      // Handle if the error if that the email is incorrect
      // Or any other kind of error
      if (err.response) {
        if (err.response.status === 400)
          alert(
            "No hemos encontrado ninguna cuenta con este correo electrónico."
          );
      } else {
        alert("Algo salió mal...");
      }

      console.log(err);
    }
  }

  return (
    <section>
      <h1>
        {langs[lang]?.reset_password?.title ??
          langs["es"]?.reset_password?.title}
      </h1>
      <p className="subtitle">
        {langs[lang]?.reset_password?.subtitle_link ??
          langs["es"]?.reset_password?.subtitle_link}
      </p>

      <div>
        <InputText
          required
          className="register-form__text-input-wrapper"
          InputProps={{
            className: "register-form__text-input",
          }}
          label={langs[lang]?.login_register.email}
          onChange={setValue}
          name="email"
          rule={(value) => isEmail(value)}
          variant="outlined"
        />

        <Button onClick={sendEmail} typeStyle="block">
          {langs[lang]?.reset_password?.button_send_reset_link ??
            langs["es"]?.reset_password?.button_send_reset_link}
        </Button>
        <div style={{ marginTop: "1rem" }}>
          <Link
            className="link"
            style={{ textAlign: "center", display: "block" }}
            to="/login"
          >
            {langs[lang]?.reset_password?.button_go_back ??
              langs["es"]?.reset_password?.button_go_back}
          </Link>
        </div>
      </div>
    </section>
  );
}

function SendResetPasswordLinkRoute() {
  return (
    <>
      <SendResetPasswordLink />
    </>
  );
}

function ResetPassword() {
  const queryParameters = new URLSearchParams(window.location.search);
  const lang = useContext(LangContext);

  const [newPassword, setNewPassword] = useState();
  const [isError, setIsError] = useState();
  const token = queryParameters.get("token");

  function alertError() {
    setIsError(true);
  }

  function setValuePassword(e) {
    setNewPassword(e.target.value);
    setIsError(false);
  }

  async function resetPassword() {
    try {
      if (isError) {
        alert(langs[lang]?.login_register?.validation?.password_error);
      }

      const { status } = await axios.post(
        apiUrl + "/reset-password-team",
        {
          token,
          newPassword,
        },
        { "Content-Type": "application/json" }
      );

      // Should always access into this if, but just in case, we make sure that the operation has been completed successfully
      if (status === 200) {
        alert(
          "Se ha cambiado correctamente tu contraseña. Ya puedes iniciar sesión."
        );

        window.location.href = frontUrl + "/login";
      }
    } catch (err) {
      // Handle if the error if that the email is incorrect
      // Or any other kind of error
      if (err.response) {
        if (err.response.status >= 400 && err.response.status < 500) {
          alert(
            "La clave para cambiar la contraseña no es válida. Posiblemente haya caducado."
          );
        }
      } else {
        alert("Algo salió mal...");
      }

      console.log(err);
    }
  }

  return (
    <section>
      <h1>
        {langs[lang]?.reset_password?.title ??
          langs["es"]?.reset_password?.title}
      </h1>
      <p className="subtitle">
        {langs[lang]?.reset_password?.subtitle ??
          langs["es"]?.reset_password?.subtitle}
      </p>

      <div>
        <InputPassword
          required
          onChange={setValuePassword}
          name="password"
          label={
            langs[lang]?.reset_password?.new_password ??
            langs["es"]?.reset_password?.new_password
          }
          wrapperClassName="login-form__text-input-wrapper"
          innerClassName="login-form__text-input"
          onError={alertError}
        />

        <Button onClick={resetPassword} typeStyle="block">
          {langs[lang]?.reset_password?.button_reset ??
            langs["es"]?.reset_password?.button_reset}
        </Button>
        <div style={{ marginTop: "1rem" }}>
          <Link
            className="link"
            style={{ display: "block", textAlign: "center" }}
            to="/login"
          >
            {langs[lang]?.reset_password?.button_go_back ??
              langs["es"]?.reset_password?.button_go_back}
          </Link>
        </div>
      </div>
    </section>
  );
}

function ResetPasswordRoute() {
  return (
    <>
      <ResetPassword />
    </>
  );
}

function LoginForm({ setLoggedUserKey }) {
  const keys = {
    form: "form",
    sent: "sent",
    logged_in: "logged_in",
    server_error: "server_error",
  };

  const lang = useContext(LangContext);
  const [userData, setUserData] = useState({});
  const [userId, setUserId] = useState(undefined);
  const [username, setTeamNickname] = useState(undefined);
  const [formState, setFormState] = useState(keys.form);
  const [inputAlreadyUsed, setInputAlreadyUsed] = useState({});
  const [isThereAnyFormError, setIsThereAnyFormError] = useState(false);

  useEffect(() => {
    if (formState === keys.server_error) {
      alert("Incorrect password or username");
    }
  }, [formState, keys.server_error]);

  useEffect(() => {
    setIsThereAnyFormError(false);
  }, [userData]);

  /*
    This component, will:
    1. Render a form to let the user login
    2. Send the information to the server, while rendering a feedback animation
    3. When server returns 200 status, redirect the user to his profile page
    (4.) In case of server error, will display a feedback message
    */
  const loginForm = (
    <section className="Login">
      <div className="Register__logo-wrapper">
        <img className="Register__logo" src={logo} alt="Mitali logo" />
      </div>
      <form className="login-form" onSubmit={handleSubmit}>
        <h1
          className="Login__title"
          style={{ textAlign: "center", marginBottom: "1.5rem" }}
        >
          {langs[lang]?.login_register.login_title}
        </h1>
        <ValidateTextField
          className="login-form__text-input-mui-layer"
          InputProps={{
            className: "login-form__text-input",
          }}
          label={langs[lang]?.login_register.email}
          required
          onChange={setValue}
          name="email"
          icon={userIcon}
          variant="outlined"
          rule={(value) => isEmail(value)}
          error={inputAlreadyUsed?.email ?? false}
          errorMsg={
            inputAlreadyUsed?.email ?? false
              ? "This username already used"
              : "Username can only contain letters, numbers, dots (.) and underscores (_)"
          }
          onError={alertError}
        />
        <InputPassword
          required
          onChange={setValue}
          name="password"
          label={langs[lang]?.login_register.password}
          wrapperClassName="login-form__text-input-wrapper"
          innerClassName="login-form__text-input"
          hideErrors={true}
        />
        <Link
          class="link link--block-right"
          to="/send-reset-password-link-team"
        >
          ¿Olvidaste la contraseña?
        </Link>

        <Centerer>
          <Button
            typeStyle="block"
            style={{ marginTop: "2rem" }}
            type="submit"
            variant="contained"
          >
            {langs[lang]?.login_register.login}
          </Button>
        </Centerer>
        <Centerer
          style={{
            marginTop: "1rem",
            fontSize: ".71rem",
            color: "#aaaaaa",
          }}
        >
          {langs[lang]?.login_register.new_to_mitali}&nbsp;
          <Link className="link" to="/register">
            {langs[lang]?.login_register.create_account}
          </Link>
        </Centerer>
      </form>
    </section>
  );

  const toReturn = {
    [keys.form]: loginForm,

    [keys.sent]: (
      <section className="creating-user">
        <h1 className="creating-user__title">Logging in...</h1>
        <Centerer>
          <TrophiesCarrousel />
        </Centerer>
      </section>
    ),
    [keys.logged_in]: <ForceRedirect to={`/members/${username}`} />,
    [keys.server_error]: <>{loginForm}</>,
  };

  async function setValue(e, value) {
    const { target } = e;
    const { name } = target;
    if (!value) {
      value = target.value;
    }

    setInputAlreadyUsed({ ...inputAlreadyUsed, ...{ [name]: false } });
    setUserData({ ...userData, ...{ [name]: value } });
  }

  function handleSubmit(e) {
    e.preventDefault();
    sendData();
  }

  function alertError() {
    setIsThereAnyFormError(true);
  }

  function areErrorsInForm() {
    return isThereAnyFormError;
  }

  async function sendData() {
    if (areErrorsInForm()) {
      alert("You need to correct some errors before you login");
      return;
    }

    setFormState(keys.sent);
    try {
      const req = await axios.post(`${apiUrl}/team-login`, userData, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      });

      if (req.status !== 200) {
        throw new Error(req.status);
      }

      const { status, data } = req;

      // If everythink ok, redirect
      if (status === 200) {
        const {
          session_token: sessionToken,
          team_id: userId,
          team_nickname: givenTeamNickname,
        } = data;

        saveSession(sessionToken);
        saveUserId(userId);
        setTeamNickname(givenTeamNickname);
        saveTeamNickname(givenTeamNickname);
        setLoggedUserKey(sessionToken);
        setUserId(userId);
        setFormState(keys.logged_in);
        const alreadyUsed = data.already_used;
        setInputAlreadyUsed(alreadyUsed);
        return;
      }

      // If not, there's an error
      setFormState(keys.server_error);
    } catch (err) {
      console.log(err);
      if (err === 404) {
      }

      setFormState(keys.server_error);
    }
  }

  return toReturn[formState];
}

function LoginRoute({ setLoggedUserKey }) {
  const loggedUserKey = useContext(LoggedUserKey);
  const sessionUsername = getTeamNickname();

  if (loggedUserKey && sessionUsername) {
    return <Navigate to={`/members/${sessionUsername}`} />;
  }

  return (
    <>
      <LoginForm setLoggedUserKey={setLoggedUserKey} />
    </>
  );
}

function RegisterForm({ setLoggedUserKey }) {
  const keys = {
    form: "form",
    sent: "sent",
    logged_in: "logged_in",
    user_created: "user_created",
    server_error: "server_error",
  };

  const lang = useContext(LangContext);
  const [userData, setUserData] = useState({});
  const [userId, setUserId] = useState(undefined);
  const [username, setTeamNickname] = useState(undefined);
  const [formState, setFormState] = useState(keys.form);
  const [inputAlreadyUsed, setInputAlreadyUsed] = useState({});
  const [isThereAnyFormError, setIsThereAnyFormError] = useState(false);

  useEffect(() => {
    setIsThereAnyFormError(false);
  }, [userData]);

  /*
  This component, will:
  1. Render a form to let the user register
  2. Send the information to the server, while rendering a feedback animation
  3. When server returns 200 status, redirect the user to his brand new profile page
  (4.) In case of server error, will display a feedback message
  */
  const toReturn = {
    [keys.logged_in]: <ForceRedirect to={`/members/${username}`} />,

    [keys.form]: (
      <>
        <section className="Register">
          <div className="Register__logo-wrapper">
            <img className="Register__logo" src={logo} alt="Mitali logo" />
          </div>

          <form className="register-form" onSubmit={handleSubmit}>
            <h1 className="Register__title">
              {langs[lang]?.login_register.register_title}
            </h1>
            <h2 className="Register__subtitle">
              {langs[lang]?.login_register.register_subtitle}
            </h2>
            <InputText
              className="register-form__text-input-wrapper"
              InputProps={{
                className: "register-form__text-input",
              }}
              required
              icon={userIcon}
              label={langs[lang]?.login_register.official_name}
              onChange={setValue}
              name="official_name"
              variant="outlined"
              rule={(value) => value.trim().split(" ").length >= 3}
              error={inputAlreadyUsed?.official_name ?? false}
              errorMsg={
                inputAlreadyUsed?.official_name
                  ? langs[lang]?.login_register?.validation
                    ?.official_name_used ??
                  langs[defaultLang]?.login_register?.validation
                    ?.official_name_used
                  : langs[lang]?.login_register?.validation
                    ?.official_name_error ??
                  langs[defaultLang]?.login_register?.validation
                    ?.official_name_error
              }
              onError={alertError}
            />
            <ValidateTextField
              className="register-form__text-input-mui-layer"
              InputProps={{
                className: "register-form__text-input",
              }}
              icon={userIcon}
              label={langs[lang]?.login_register.nickname}
              required
              onChange={setValue}
              name="nickname"
              variant="outlined"
              rule={(value) => value.match(usernameRegex)}
              error={inputAlreadyUsed?.nickname ?? false}
              errorMsg={
                inputAlreadyUsed?.nickname ?? false
                  ? langs[lang]?.login_register?.validation?.nickname_used ??
                  langs[defaultLang]?.login_register?.validation
                    ?.nickname_used
                  : langs[lang]?.login_register?.validation?.nickname_error ??
                  langs[defaultLang]?.login_register?.validation
                    ?.nickname_error
              }
              onError={alertError}
            />

            <ChooseDisciplines
              name="disciplines"
              setValue={(value) =>
                setValue({ target: { name: "disciplines" } }, value)
              }
            />

            <ValidateTextField
              required
              icon={emailIcon}
              className="register-form__text-input-wrapper"
              InputProps={{
                className: "register-form__text-input",
              }}
              label={langs[lang]?.login_register.email}
              onChange={setValue}
              name="email"
              rule={(value) => isEmail(value)}
              error={inputAlreadyUsed?.email ?? false}
              errorMsg={
                inputAlreadyUsed?.email ?? false
                  ? langs[lang]?.login_register?.validation?.email_used ??
                  langs[defaultLang]?.login_register?.validation?.email_used
                  : langs[lang]?.login_register?.validation?.email_error ??
                  langs[defaultLang]?.login_register?.validation?.email_error
              }
              variant="outlined"
              onError={alertError}
            />
            <InputPassword
              required
              onChange={setValue}
              onError={alertError}
              name="password"
              label={langs[lang]?.login_register.password}
              wrapperClassName="register-form__text-input-wrapper"
              innerClassName="register-form__text-input"
            />
            <div style={{ fontSize: ".75rem", marginTop: "2rem" }}>
              <label
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: ".5rem",
                  fontSize: ".7rem",
                }}
              >
                <Checkbox required />
                <span>
                  {langs[lang]?.login_register.privacy_1}{" "}
                  <a
                    style={{
                      display: "inline-block",
                      zIndex: "2",
                      fontSize: "inherit",
                    }}
                    className="link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://mitali.app/privacy.html"
                  >
                    {langs[lang]?.login_register.privacy_2}
                  </a>{" "}
                  {langs[lang]?.login_register.privacy_3}
                </span>
              </label>
            </div>

            <Centerer>
              <Button
                style={{ marginTop: "1rem" }}
                type="submit"
                typeStyle="block"
              >
                {langs[lang]?.login_register.create_account}
              </Button>
            </Centerer>
            <Centerer
              style={{
                marginTop: "1rem",
                fontSize: ".71rem",
                color: "#aaaaaa",
              }}
            >
              {langs[lang]?.login_register.already_have_account}&nbsp;
              <Link className="link" to="/login">
                {langs[lang]?.login_register.login}
              </Link>
            </Centerer>
          </form>
        </section>
      </>
    ),

    [keys.sent]: (
      <section className="creating-user">
        <h1 className="creating-user__title">We're creating your profile</h1>
        <h2 className="creating-user__subtitle">
          Are you excited to become a Winner?
        </h2>
        <Centerer>
          <TrophiesCarrousel />
        </Centerer>
      </section>
    ),
    [keys.user_created]: <ForceRedirect to={`/members/${username}`} />,
    [keys.server_error]: <Navigate to={`/server-error?back=register`} />,
  };

  async function setValue(e, value) {
    const { target } = e;
    const { name } = target;
    if (!value) {
      value = target.value;
    }

    // We will avoid sending unnecessary sensitive data to the server
    // And we also want to allow creating more than one user with the same full name
    if (name !== "password" && name !== "official_name") {
      const { data: isAlreadyUsed } = await axios.get(
        `${apiUrl}/teams-already-used?${name}=${value}`
      );

      if (isAlreadyUsed) {
        setInputAlreadyUsed({ ...inputAlreadyUsed, ...{ [name]: true } });
        return;
      }
    }

    setInputAlreadyUsed({ ...inputAlreadyUsed, ...{ [name]: false } });
    setUserData({ ...userData, ...{ [name]: value } });
  }

  function handleSubmit(e) {
    e.preventDefault();
    sendData();
  }

  function alertError() {
    setIsThereAnyFormError(true);
  }

  function areErrorsInForm() {
    return isThereAnyFormError;
  }

  async function sendData() {
    if (areErrorsInForm()) {
      alert("You need to correct some errors before you register");
      return;
    }

    setFormState(keys.sent);
    try {
      const req = await axios.post(`${apiUrl}/create-team`, userData, {
        headers: {
          // Overwrite Axios's automatically set Content-Type
          "Content-Type": "application/json",
        },
      });

      console.log(req);

      if (req.status !== 200) {
        throw new Error(req.status);
      }

      const { status, data } = req;

      // If everythink ok, redirect
      if (status === 200) {
        const {
          session_token: sessionToken,
          team_id: userId,
          team_nickname: givenTeamNickname,
        } = data;

        saveSession(sessionToken);
        saveUserId(userId);
        setTeamNickname(givenTeamNickname);
        saveTeamNickname(givenTeamNickname);
        setLoggedUserKey(sessionToken);
        setUserId(userId);
        setFormState(keys.logged_in);
        const alreadyUsed = data.already_used;
        setInputAlreadyUsed(alreadyUsed);
        return;
      }

      // If not, there's an error
      setFormState(keys.server_error);
    } catch (err) {
      console.log(err);
      if (err === 404) {
      }

      setFormState(keys.server_error);
    }
  }

  return toReturn[formState];
}

function RegisterRoute({ setLoggedUserKey }) {
  const lang = useContext(LangContext);
  return <RegisterForm setLoggedUserKey={setLoggedUserKey} />;
}

function Error404Route() {
  const lang = useContext(LangContext);
  return (
    <section className="Error404">
      <h1 className="Error404__title">
        {langs[lang]?.error_404?.title ?? langs[defaultLang]?.error_404?.title}
      </h1>
      <h2 className="Error404__subtitle">
        {langs[lang]?.error_404?.subtitle ??
          langs[defaultLang]?.error_404?.subtitle}
      </h2>
      <p className="Error404__text">
        {langs[lang]?.error_404?.text ?? langs[defaultLang]?.error_404?.text}
      </p>
      <Centerer>
        <div className="Error404__go-back">
          <Link to="/members">
            <Button variant="contained" size="large" color="primary">
              {langs[lang]?.error_404?.go_back ??
                langs[defaultLang]?.error_404?.go_back}
            </Button>
          </Link>
        </div>
      </Centerer>
      <img
        className="background-image"
        alt="Error 404 Splash img"
        src={image404}
      />
    </section>
  );
}

function VerifyEmailRoute() {
  const [status, setStatus] = useState(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token");

  async function execRequest() {
    try {
      const res = await axios.get(apiUrl + "/verify-email-team?token=" + token);
      setStatus(res.status);
    } catch (err) {
      setStatus(500);
    }
  }

  useEffect(function () {
    execRequest();
  }, []);

  if (!status) {
    return (
      <>
        <h1>Estamos comprobando tu email...</h1>
        <h2>Te redirigiremos en beve</h2>
      </>
    );
  }

  if (status === 200) {
    alert("¡Verificación completada!");
  } else {
    alert("Tu cuenta ya está verificada");
  }

  window.location.href = frontUrl;

  return <h1>Te estamos redirigiendo a la página principal...</h1>;
}

function App() {
  const [loggedUserKey, setLoggedUserKey] = useState(getSession());
  const [localSettings, setLocalSettings] = useState({});
  const [tutorialPhase, setTutorialPhase] = useState(-1);
  const [profileId, setProfileId] = useState(undefined);
  const [userData, setUserData] = useState({});
  const [showSplashscreen, setShowSplashscreen] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [displayTrophy, setDisplayTrophy] = useState(undefined);
  const [showingTrophy, setShowingTrophy] = useState(undefined);

  function showTrophy(trophy) {
    setDisplayTrophy(true);
    setShowingTrophy(trophy);
  }

  useEffect(function () {
    setShowSplashscreen(true);
    sleep(6000).then(function () {
      setShowSplashscreen(false);
    });
  }, []);

  // To redirect the user between pages when using the tutorial
  const [lastTutorialClick, setLastTutorialClick] = useState(1);
  const [forceRoute, setForceRoute] = useState(undefined);
  const [askTutorialPopup, setAskTutorialPopup] = useState(false);

  const bind = useLongPress((e) => e.preventDefault());

  const optionsState = useState(getLocalKey("mitali", true));

  const [options, setOptions] = optionsState;

  useEffect(() => {
    setOptions(localSettings);
  }, [localSettings]);

  const isOwner = getUserId() === userData?._id ?? false;

  const userTypeContextValue = isOwner ? userTypes.owner : userTypes.visitor;

  // For things such as options, first time, etc.
  function loadLocalSettings() {
    try {
      const rawData = localStorage.getItem("mitali");
      if (rawData === null) {
        throw new Error({ type: "doesn't exist" });
      }
      const data = JSON.parse(rawData);
      setLocalSettings(data);
      return data;
    } catch (err) {
      // If doesnt exists (or invalid), we create an empty dataset
      setLocalSettings({});
      const sessionUserId = getUserId();
      if (!loggedUserKey) return;
      if (!sessionUserId) return;

      localStorage.setItem("mitali", JSON.stringify({}));
      isFirstTime();
      console.error(err);
    }
  }

  function askForTutorial() {
    setAskTutorialPopup(true);
  }

  // When is the user's first time in the app
  function isFirstTime() {
    return;
    askForTutorial();
  }

  function nextPhase() {
    setTutorialPhase(tutorialPhase + 1);
    setLastTutorialClick(1);
    setForceRoute(undefined);
  }

  function prevPhase() {
    setTutorialPhase(tutorialPhase - 1);
    setLastTutorialClick(-1);
    setForceRoute(undefined);
  }

  function finishTutorial() {
    setTutorialPhase(-1);
    setForceRoute(undefined);
  }

  function startTutorial() {
    setTutorialPhase(0);
    setForceRoute("/members/" + userData._id);
  }

  function handleLogout() {
    setLoggedUserKey(undefined);
    removeSession();
    removeUserId();
    window.location.href = "/login";
  }

  if (isLoading) return <Loader />;

  return (
    <LoggedUserKey.Provider value={loggedUserKey}>
      <LangContext.Provider value={options?.language ?? "es"}>
        <UserTypeContext.Provider value={userTypeContextValue}>
          <ThemeContext.Provider value={options}>
            <UserDataContext.Provider value={userData}>
              <TutorialPhase.Provider value={tutorialPhase}>
                {showSplashscreen && false && (
                  <div className="splash-screen">
                    <img
                      className="splash-screen__logo"
                      src="/logo192.png"
                      alt="Splashscreen logo"
                    />
                    <h3 className="splash-screen__slogan">
                      Share your
                      <br />
                      success
                    </h3>
                  </div>
                )}

                {displayTrophy && (
                  <PopupTrophy
                    trophy={showingTrophy}
                    display={displayTrophy}
                    setDisplay={setDisplayTrophy}
                    highlightTrophy={showingTrophy?.highlight}
                  />
                )}

                <Router>
                  <div
                    className={`App ${options?.dark_mode ? "dark-mode" : ""}`}
                    {...bind()}
                    onContextMenu={(e) => e.preventDefault()}
                  >
                    <TutorialRedirector
                      currentPhase={tutorialPhase}
                      forceRoute={forceRoute}
                      lastClick={lastTutorialClick}
                    />
                    <Routes>
                      <Route
                        path="/verify-email"
                        element={<VerifyEmailRoute />}
                      ></Route>
                      <Route
                        path="/"
                        element={<Navigate to="/login" />}
                      ></Route>
                      <Route
                        path="/members"
                        element={<Navigate to="/login" />}
                      ></Route>
                      <Route
                        path="/members/:profileId"
                        element={
                          <MembersListRoute
                            nextPhase={nextPhase}
                            setProfileId={setProfileId}
                            setUserData={setUserData}
                            userData={userData}
                            setIsLoading={setIsloading}
                            loadLocalSettings={loadLocalSettings}
                          />
                        }
                      ></Route>
                      <Route
                        path="/shelf/:profileId"
                        element={
                          <ShelfRoute
                            nextPhase={nextPhase}
                            setProfileId={setProfileId}
                            setUserData={setUserData}
                            showingTrophy={showingTrophy}
                            showTrophy={showTrophy}
                            displayTrophy={displayTrophy}
                            setDisplayTrophy={setDisplayTrophy}
                            userData={userData}
                            setIsLoading={setIsloading}
                            loadLocalSettings={loadLocalSettings}
                          />
                        }
                      ></Route>
                      <Route
                        path="/ranking/:profileId"
                        element={
                          <MembersRankingRoute
                            nextPhase={nextPhase}
                            setProfileId={setProfileId}
                            setUserData={setUserData}
                            userData={userData}
                            setIsLoading={setIsloading}
                            loadLocalSettings={loadLocalSettings}
                          />
                        }
                      ></Route>
                      <Route
                        path="/server-error"
                        element={<ServerError />}
                      ></Route>
                      <Route
                        path="/not-found"
                        element={<Error404Route />}
                      ></Route>
                      <Route
                        path="/send-reset-password-link"
                        element={<SendResetPasswordLinkRoute />}
                      ></Route>
                      <Route
                        path="/reset-password"
                        element={<ResetPasswordRoute />}
                      ></Route>
                      <Route
                        path="/settings"
                        element={
                          <SettingsRoute
                            nextPhase={nextPhase}
                            optionsState={optionsState}
                            userData={userData}
                            setUserData={setUserData}
                            currentPhase={tutorialPhase}
                            startTutorial={startTutorial}
                            handleLogout={handleLogout}
                            loadLocalSettings={loadLocalSettings}
                          />
                        }
                      ></Route>
                      <Route
                        path="/register"
                        element={
                          <RegisterRoute setLoggedUserKey={setLocalSettings} />
                        }
                      />
                      <Route
                        path="/login"
                        element={
                          <LoginRoute setLoggedUserKey={setLoggedUserKey} />
                        }
                      />
                      <Route path="*" element={<LoginRoute />}></Route>
                    </Routes>
                    <TutorialExplanation
                      tutorialDataset={tutorialDataset}
                      prevPhase={prevPhase}
                      nextPhase={nextPhase}
                      finishTutorial={finishTutorial}
                    />
                  </div>
                </Router>
              </TutorialPhase.Provider>
            </UserDataContext.Provider>
          </ThemeContext.Provider>
        </UserTypeContext.Provider>
      </LangContext.Provider>
    </LoggedUserKey.Provider>
  );
}

export default App;
