import React, { useContext } from "react";
import axios from "axios";
import { apiUrl } from "../../js/context";

import "../../styles/ui/PicUploader.scss";

import { LoggedUserKey } from "../App";
import { blobify, removeExifImg } from "../../js/functions";
import { FiEdit2 } from "react-icons/fi";

export default function PicUploader({
  action = "/upload-profile-pic-team",
  inputName,
  className,
  children,
  style,
}) {
  const loggedUserKey = useContext(LoggedUserKey);
  async function sendFile(file) {
    file = await removeExifImg(file);

    let formData = new FormData();
    formData.set("image", file);
    const headers = {
      authorization: `Bearer ${loggedUserKey}`,
      "content-type": "multipart/form-data",
    };

    console.log(apiUrl + action);

    try {
      const url = apiUrl + action;
      if (!url) return;
      const { status } = await axios.post(apiUrl + action, formData, {
        headers,
        withCredentials: true,
      });

      console.log(action, status);

      if (status !== 200) {
        throw new Error();
      }

      window.location.reload();
    } catch (err) {
      console.log(err);
      alert("Only png, jpg and jpeg available. Max size: 9MB");
    }
  }

  async function uploadPicture(e) {
    const [file] = e.target.files;
    if (!file) return;
    await sendFile(file);
  }

  return (
    <div className={`PicUploader ${className}`} style={{ ...style }}>
      <input
        className="PicUploader__input"
        name={inputName}
        onChange={uploadPicture}
        type="file"
      />
      {children}
      <div className="PicUploader__input-button-wrapper">
        <input
          className="PicUploader__input"
          name={inputName}
          onChange={uploadPicture}
          type="file"
        />
        <FiEdit2 style={{ color: "white" }} />
      </div>
    </div>
  );
}
