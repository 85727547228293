import React, { useState } from "react";
import { IoMdCheckmark } from "react-icons/io";

import "../../styles/ui/Checkbox.scss";

export default function Checkbox(props) {
  const [isChecked, setIsChecked] = useState(props?.defaultValue ?? false);

  function updateState(e) {
    setIsChecked(e.target.checked);
  }

  return (
    <div className="Checkbox">
      <div
        className={`Checkbox__graphic ${
          isChecked && "Checkbox__graphic--checked"
        }`}
      >
        <input
          type="checkbox"
          required={props?.required}
          onChange={updateState}
          className="Checkbox__checkbox"
        />
        {isChecked && <div className="Checkbox__tick">
          <IoMdCheckmark />
        </div>}
      </div>

    </div>
  );
}
