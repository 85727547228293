import { createTheme } from "@mui/material/styles";

export const colors = {
  mainColor: "#0A94D5",
  coolGray: "#a7a5c6",
  yellow: "#e3c500",
  white: "#f5eccd",
};

const theme = createTheme({
  typography: {
    button: {
      textTransform: "none",
    },
  },

  palette: {
    primary: {
      main: colors.mainColor,
    },
    secondary: {
      main: colors.coolGray,
    },
    // Provide every color token (light, main, dark, and contrastText) when using
    custom: {
      light: "#ffa726",
      main: "#f57c00",
      dark: "#ef6c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default theme;
