import React from "react";

export default function ImageWithFallback(props) {
    function addDefaultSrc({ currentTarget }) {
        currentTarget.onerror = null; // prevents looping
        // The default pic is in front url, because even if backend fails, frontend could keep being alive
        currentTarget.src = props?.defaultUrl;
    }

    return (
        <img
            src={props?.imageUrl}
            onError={addDefaultSrc}
            {...props}
        />
    )
}