import React from "react";

import "../../styles/ui/Button.scss";

export default function Button(props) {
  const { children, typeStyle, style } = props;

  const block = typeStyle === "block";
  const gray = typeStyle === "gray";
  const blue = typeStyle === "blue";

  return (
    <button
      style={style}
      {...props}
      className={`Button ${gray && "Button--gray"} ${blue && "Button--blue"} ${
        block ? "Button--block" : "Button--square"
      }`}
    >
      {children}
    </button>
  );
}
