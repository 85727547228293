import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";
import { apiUrl, selectStyles } from "../../js/context.js";
import { LangContext } from "../App.jsx";
import langs from "../../../langs.js";
import Select from "react-select";
import crossIcon from "../../images/icons/Close.svg";
import "../../styles/ui/ChooseDisciplines.scss";

const options = [
  { name: "Chess", id: 0 },
  { name: "Running", id: 1 },
  { name: "Cycling", id: 2 },
  { name: "Swimming", id: 3 },
  { name: "Sailing", id: 4 },
  { name: "Tennis", id: 5 },
];

export default function ChooseDisciplines({ setValue, defaultValue = [] }) {
  const lang = useContext(LangContext);
  const [selectableOptions, setSelectableOptions] = useState([]);
  const [currentValues, setCurrentValues] = useState(defaultValue);


  async function fetchDisciplines() {
    try {
      5
      const { data: disciplines } = await axios.get(apiUrl + "/disciplines");
      setSelectableOptions(
        disciplines.filter((option) => !currentValues.includes(option))
      );
    } catch (err) {
      console.log(err);
      setSelectableOptions([]);
    }
  }

  function removeSelection(id) {
    const newSelections = defaultValue.filter((option) => option._id !== id);

    setValue(newSelections);
    setCurrentValues(newSelections)

  }

  function onChange(e) {
    const newDiscipline = { name: e.value, _id: e._id };
    let newSelections = [...currentValues, newDiscipline];

    newSelections = newSelections.filter(
      (obj, index) =>
        newSelections.findIndex((item) => item._id === obj._id) === index
    );

    //setSelectedOptions(newSelections);

    // To make the value accessible, we have to make this ad-oc thing
    setValue(newSelections);
    setCurrentValues(newSelections)
  }

  useEffect(() => {
    fetchDisciplines();
    setCurrentValues(defaultValue)
  }, []);


  useEffect(function () {
    setCurrentValues(defaultValue);
  }, [defaultValue])

  // To avoid rendering a separator between text & chevron
  const IndicatorSeparator = function () { };

  const options = selectableOptions
    .filter((option) => !defaultValue.find((item) => item._id === option._id))
    .map((opt) => {
      return { value: opt.name, label: opt.name, _id: opt._id };
    });

  return (
    <div className="ChooseDisciplines">
      <Select
        aria-label={defaultValue}
        labelId="select-language-label"
        id="select-language"
        label="Idioma"
        placeholder={
          langs?.[lang]?.settings?.select_discipline ??
          langs?.["es"]?.settings?.select_discipline
        }
        options={options}
        components={{ IndicatorSeparator }}
        styles={selectStyles}
        onChange={onChange}
        separatorIndicator={false}
      />

      <ul className="ChooseDisciplines__selected-options">
        {currentValues?.map(function (option) {
          return (
            <li className="ChooseDisciplines__selected-option">
              {option.name}
              <button
                style={{
                  padding: 0,
                  background: "none",
                  border: "none",
                  textAlign: "center",
                  margin: 0,
                }}
                onClick={() => removeSelection(option._id)}
              >
                <img style={{ height: "1rem" }} src={crossIcon} alt="" />
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
