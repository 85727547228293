import React from "react";

import leftArrow from "../../images/icons/Left Arrow Black.svg";
import rightArrow from "../../images/icons/Right Arrow Black.svg";

import "../../styles/ui/YearPicker.scss";

export default function YearPicker({ year, setYear, minYear, maxYear }) {
    function handleLeftButton() {
        if (year === maxYear) return;

        // It seems it should substract one (as left is always go back), but here, I think the order is inverted
        // Going back means go nearer to current year
        setYear(year + 1);
    }

    function handleRightButton() {
        if (year === minYear) return;

        setYear(year - 1);
    }

    return (
        <div className="YearPicker">
            <button className={`YearPicker__btn ${year === maxYear && "YearPicker__btn--disabled"}`} onClick={handleLeftButton}><img className="YearPicker__btn-icon" alt="" src={leftArrow} /></button>
            <span className="YearPicker__year-display">{year}</span>
            <button className={`YearPicker__btn ${year === minYear && "YearPicker__btn--disabled"}`} onClick={handleRightButton}><img className="YearPicker__btn-icon" alt="" src={rightArrow} /></button>
        </div>
    )
}