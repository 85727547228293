import React, { useContext } from "react";
import { MdOutlineInstallMobile } from "react-icons/md";
import Button from "./Button";

import "../../styles/ui/AskInstallApp.scss";
import FancyButton from "./FancyButton";
import { LangContext } from "../App";
import langs from "../../../langs";

let deferredPrompt = null;

window.addEventListener("beforeinstallprompt", (event) => {
  event.preventDefault();
  deferredPrompt = event;
});

export default function AskInstallApp() {
  const lang = useContext(LangContext);

  async function installAppHandler() {
    if (!deferredPrompt) {
      return;
    }

    const result = await deferredPrompt.prompt();

    if (result.outcome === "accepted") {
      window.location.reload();
    }
  }

  // Only render if the app can be installed (using Chromium engine)
  if (!deferredPrompt) return;

  return (
    <div class="AskInstallApp">
      <Button
        typeStyle="block"
        style={{ padding: ".8rem 1rem" }}
        onClick={installAppHandler}
      >
        <MdOutlineInstallMobile />{" "}
        {langs?.[lang]?.install_app ?? langs?.["es"]?.install_app}
      </Button>
    </div>
  );
}
