import React, { useEffect, useState } from "react";

import "../../styles/ui/ParallaxShelf.scss";
import { shadeColor } from "../../js/functions";

export default function ParallaxShelf({ children: trophies, color, style }) {
  const inclination = 10;

  return (
    <div className="ParallaxShelf" style={style}>
      <div className="ParallaxShelf__front" style={{ background: color }}></div>
      <div
        className="ParallaxShelf__after"
        style={{
          height: `${inclination}px`,
          background: `${shadeColor(color, 20)}`,
        }}
      ></div>

      {trophies}
    </div>
  );
}
