import React, { useEffect, useState } from "react";
import InputText from "./InputText";

// Rule must be a function, that can return true or false
export default function ValidateTextField(props) {
  const [currentValue, setCurrentValue] = useState("");
  const isError =
    (currentValue.length && !props?.rule(currentValue)) || props?.error;
  const displayErrorMsg = isError ? props?.errorMsg : "";

  useEffect(() => {
    if (!props?.rule(currentValue)) {
      return;
    }

    if (props?.setValue) {
      props?.setValue(props?.name, currentValue);
      return;
    }

    if (props?.onBlur) {
      props?.onBlur(props?.name, currentValue);
      return;
    }
  }, [currentValue]);

  if (isError) {
    props?.onError(props?.name);
  }

  return (
    <div className="register-form__text-input-wrapper">
      <InputText
        {...props}
        onChange={(e) => {
          setCurrentValue(e.target.value);
          if (props?.onChange) {
            props?.onChange(e);
            return;
          }
        }}
        onBlur={(e) => {
          setCurrentValue(e.target.value);
        }}
      />
      <span className="input-error">{displayErrorMsg}</span>
    </div>
  );
}
