const tutorialDataset = {
  en: [
    {
      title: "Click on the button to share your shelf",
      explanation:
        "You can copy the link and paste it on your Instagram bio, share it with your friends, etc.",
      path: "/shelf",
    },

    {
      title: "Personalize your shelf!",
      explanation:
        "Click on these magic buttons to change the color of the shelf and the background.",
      path: "/shelf",
    },

    {
      title: "Settings",
      explanation: "Click on the gear icon to change your settings and data",
      path: "/shelf",
    },

    {
      title: "Change your profile data",
      explanation: "",
      path: "/settings",
    },

    {
      title: "Change app settings",
      explanation: "",
      path: "/settings",
    },

    {
      title: "Want to check how does your shelf look to visitors?",
      explanation: "Enable the Preview mode.",
      path: "/settings",
    },

    {
      title: "Want the tutorial again?",
      explanation:
        "You can always click the 'Display tutorial' button. You can only display the tutorial if you have the Preview mode disabled.",
      path: "/settings",
    },
  ],

  es: [
    {
      title: "Haz clic en el botón para compartir tu estante",
      explanation:
        "Puedes copiar el enlace y pegarlo en la biografía de tu Instagram, compartirlo con tus amigos, etc.",
      path: "/shelf",
    },

    {
      title: "¡Personaliza tu estante!",
      explanation:
        "Haz clic en estos botones mágicos para cambiar el color del estante y el fondo.",
      path: "/shelf",
    },

    {
      title: "Configuración",
      explanation:
        "Haz clic en el icono de la tuerca para cambiar tus ajustes y datos.",
      path: "/shelf",
    },

    {
      title: "Cambia tus datos del perfil",
      explanation: "",
      path: "/settings",
    },

    {
      title: "Cambia los ajustes de la aplicación",
      explanation: "",
      path: "/settings",
    },

    {
      title: "¿Quieres saber cómo ven tu estante los visitantes?",
      explanation: "Activa el modo de vista previa.",
      path: "/settings",
    },

    {
      title: "¿Quieres ver el tutorial de nuevo?",
      explanation:
        "Siempre puedes hacer clic en el botón 'Mostrar tutorial'. Solo puedes ver el tutorial si tienes desactivado el modo de vista previa.",
      path: "/settings",
    },
  ],

  ca: [
    {
      title: "Fes clic al botó per compartir la teva prestatgeria",
      explanation:
        "Pots copiar l'enllaç i enganxar-lo a la bio del teu Instagram, compartir-lo amb els teus amics, etc.",
      path: "/shelf",
    },

    {
      title: "Personalitza la teva prestatgeria!",
      explanation:
        "Fes clic en aquests botons màgics per canviar el color de la prestatgeria i el fons.",
      path: "/shelf",
    },

    {
      title: "Configuració",
      explanation:
        "Fes clic a l'icona de l'engranatge per canviar les teves opcions i dades",
      path: "/shelf",
    },

    {
      title: "Canvia les teves dades de perfil",
      explanation: "",
      path: "/settings",
    },

    {
      title: "Canvia la configuració de l'aplicació",
      explanation: "",
      path: "/settings",
    },

    {
      title: "Vols comprovar com es veu la teva prestatgeria pels visitants?",
      explanation: "Activa el mode de vista prèvia.",
      path: "/settings",
    },

    {
      title: "Vols el tutorial de nou?",
      explanation:
        "Sempre pots clicar el botó 'Mostra el tutorial'. Només pots mostrar el tutorial si tens desactivat el mode de vista prèvia.",
      path: "/settings",
    },
  ],
};

export default tutorialDataset;
