import React, { useContext } from "react";
import { LangContext, UserDataContext, UserTypeContext } from "../App";
import ProfilePic from "../ui/ProfilePic";
import { capitalizeFirstLetter } from "../../js/functions";
import { FiSettings } from "react-icons/fi";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import { userTypes } from "../App";
import uuid from "react-uuid";
import "../../styles/app/User.scss";
import langs from "../../../langs";
import { frontUrl } from "../../js/context";
import PicUploader from "../ui/PicUploader";
import Claps from "./Claps";
import { SmoothCorners } from "react-smooth-corners";
import Button from "../ui/Button";
import editIcon from "../../images/icons/Edit.svg";
import { Squircle } from "@squircle-js/react";

import membersIcon from "../../images/icons/Members_Inactive.svg";

function User({ clapsNum }) {
  let userData = useContext(UserDataContext);

  const lang = useContext(LangContext);
  const userType = useContext(UserTypeContext);

  const profilePic =
    userType === userTypes?.owner ? (
      <PicUploader>
        <ProfilePic
          size="big"
          userId={userData?._id}
          imageURL={frontUrl + "/images/default-profile.png"}
        />
      </PicUploader>
    ) : (
      <ProfilePic
        size="big"
        userId={userData?._id}
        imageURL={frontUrl + "/images/default-profile.png"}
      />
    );

  try {
    const { official_name, disciplines } = userData;
    return (
      <header className="User">
        <div className="User__profile-pic-wrapper">{profilePic}</div>
        <div className="User__data-wrapper">
          <h1 className="User__full-name">{official_name}</h1>
          <h2 className="User__nametag">
            <img
              className="User__members-count-icon"
              src={membersIcon}
              alt=""
            />{" "}
            {userData?.members?.length ?? 0}{" "}
            {langs[lang]?.profile?.members(userData?.members) ??
              langs["es"]?.profile?.members(userData?.members)}
          </h2>

          <ul className="profile-stats">
            <li className="profile-stats__stat">
              <h3 className="profile-stats__num">{clapsNum}</h3>
              <h3 className="profile-stats__stat-name">
                {langs[lang]?.profile?.claps(userData?.claps) ??
                  langs["es"]?.profile?.claps(userData?.claps)}
              </h3>
            </li>

            <li className="profile-stats__stat">
              <h3 className="profile-stats__num">
                {userData?.members?.reduce(
                  (acum, member) => (acum += member?.trophies?.length),
                  0
                ) ?? 0}
              </h3>
              <h3 className="profile-stats__stat-name">
                {langs[lang]?.profile?.trophies(userData?.trophies) ??
                  langs["es"]?.profile?.trophies(userData?.trophies)}
              </h3>
            </li>

            <li className="profile-stats__stat">
              <h3 className="profile-stats__num">
                {userData?.members?.reduce(
                  (acum, member) => (acum += member?.competitions?.length),
                  0
                ) ?? 0}
              </h3>
              <h3 className="profile-stats__stat-name">
                {langs[lang]?.profile?.medals(userData?.competitions) ??
                  langs["es"]?.profile?.medals(userData?.competitions)}
              </h3>
            </li>
          </ul>

          <ul className="User__disciplines">
            {disciplines?.map((discipline) => (
              <li className="User__discipline" key={uuid()}>
                {langs[lang]?.disciplines[discipline] ??
                  capitalizeFirstLetter(discipline.name)}
              </li>
            ))}
          </ul>
        </div>
      </header>
    );
  } catch (err) {
    console.log(err);
    console.log("Prod: Not a valid DB schema");
  }
}

export default User;
