import axios from "axios";
import { apiUrl, frontUrl } from "./context";
import langs from "../../langs";

/**
 * Gets an array and returns a string elements list,
 * separated by commas (,) but the last element,
 * that's separated by conjunction (depends on the language).
 * @param {array} list Input list.
 * @param {string} lang The lang of the output string (for the conjunction).
 */
export const humanizeList = (list, lang) => {
  const conjunctionsList = {
    es: "y",
    ca: "i",
    en: "and",
    fi: "ja",
  };

  const conj = conjunctionsList[lang];
  if (!conj) return false;

  // Remove the last item from the list, store it in a variable
  let workingList = [...list];
  const lastItem = workingList.pop();

  // Use list.join method with the new list
  let listString = workingList.join(", ");

  // Append the removed item, concatenated with the conjunction
  listString += ` ${conj} ${lastItem}`;

  return listString;
};

export function capitalizeFirstLetter(string) {
  try {
    string = string.toLowerCase();
    return string?.charAt(0)?.toUpperCase() + string.slice(1);
  } catch (err) {
    return string;
  }
}
/**
 * Gets all the data needed to display the whole shelf
 * from our internal API.
 * @param {string} userID The MongoDB ID for the doc
 * @param {function} callback The func to execute with the given data (usually, a setState)
 */
export async function fetchUserData(userID, callback) {
  const apiURL = `${apiUrl}/team?id=${userID}`;

  try {
    const { data } = await axios.get(apiURL);
    /* if (data.type === "error") {
      throw new Error();
    } */

    callback(data);
  } catch (err) {
    console.log(err);
    callback(undefined);
  }
}

/**
 * @returns {string} A random hexadecimal color
 */
export function getRandomColor() {
  return "#" + Math.random().toString(16).substr(-6);
}

/**
 * Generates a random number between an interval
 * @param {number} min The minimum number (containing it)
 * @param {number} max The maximum number (containing it)
 * @returns A number between both parameters
 */
export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function copyToClipboard(text) {
  navigator.clipboard.writeText(text);
}

export function getQueryParams() {
  const query = window.location.search;
  const params = new URLSearchParams(query);
  return params;
}

export function getUrlParam() {
  const param = window.location.pathname.substring(1);
  return param;
}

export async function jsonPost(url, params) {
  return await axios.post(url, params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function formatUserRankingPosition(position, locale) {
  try {
    const lang = locale.split("-")[0];
    const suffixPerLang = {
      es: ["º", "º", "º"],
      ca: ["r", "n", "r", "t", "e"],
      en: ["st", "nd", "rd", "th"],
    };

    const positionInArray = position - 1;
    const suffix = suffixPerLang[lang][positionInArray] ?? "th";
    return String(position) + suffix;
  } catch (err) {
    return position;
  }
}

export function createAuthHeaders(loggedUserKey) {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${loggedUserKey}`,
  };
}

export function checkIfDate(possibleDate) {
  try {
    return /\d{4}-\d{2}-\d{2}/.test(possibleDate);
  } catch (err) {
    console.log(err);
    return false;
  }
}

export function convertStringDateToTimestamp(stringDate) {
  return Math.round(new Date(stringDate).getTime() / 1000);
}

export function saveSession(token) {
  localStorage.setItem("session", JSON.stringify(token));
}

export function getSession() {
  try {
    return JSON.parse(localStorage.getItem("session"));
  } catch (err) {
    return undefined;
  }
}

export function removeSession() {
  localStorage.removeItem("session");
}

export function saveUserId(id) {
  localStorage.setItem("user-id", JSON.stringify(id));
}

export function getUserId() {
  try {
    return JSON.parse(localStorage.getItem("user-id"));
  } catch (err) {
    return undefined;
  }
}

export function removeUserId() {
  localStorage.removeItem("user-id");
}

export function saveTeamNickname(username) {
  localStorage.setItem("team-nickname", JSON.stringify(username));
}

export function getTeamNickname() {
  try {
    return JSON.parse(localStorage.getItem("team-nickname"));
  } catch (err) {
    return undefined;
  }
}

export function removeTeamNickname() {
  localStorage.removeItem("team-nickname");
}

export function blobify(str) {
  const blob = new Blob([str], {
    type: "application/json",
  });
  return blob;
}

// Changes the darkness/lightness of a color
export function shadeColor(color, percent) {
  var R = parseInt(color.substring(1, 3), 16);
  var G = parseInt(color.substring(3, 5), 16);
  var B = parseInt(color.substring(5, 7), 16);

  R = parseInt((R * (100 + percent)) / 100);
  G = parseInt((G * (100 + percent)) / 100);
  B = parseInt((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
  var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
  var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

// Returns the value of a localstorage key
export function getLocalKey(key, json = false) {
  const dataStr = localStorage.getItem(key);
  if (json) {
    try {
      return JSON.parse(dataStr);
    } catch (err) {
      return {};
    }
  }

  return dataStr ?? "";
}

// Returns an integer with the number of days between two days
export function getDateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function humanFormatDate(dateStr, lang = "es") {
  try {
    const months = langs[lang].months;

    const [day, month, year] = dateStr?.split("/");

    return `${day} ${capitalizeFirstLetter(months[month - 1])} ${year}`;
  } catch (err) {
    console.log(err);
    return dateStr;
  }
}

// Checks if a string is made only by digits
export function checkIsNumber(string) {
  return /^\d+$/.test(string);
}

// As sometimes we have stored trophies in different dates
export function createDateFromTrophyFormat(date) {
  if (checkIsNumber(date)) {
    date = Number(date) * 1000;
  }

  return new Date(date);
}

// Calculates a score of a member, based on his medals & trophies
// If we pas disciplines as param, is to avoid those trophies that aren't part of those disciplines
export function calcScore(member, disciplines = []) {
  const multipliers = {
    gold: 15,
    silver: 10,
    bronze: 5,
    medal: 1,
  };

  disciplines = disciplines.map((disci) => disci.name);

  let goldenTrophies = member?.trophies?.filter(
    (trophy) => Number(trophy?.user_position) === 1
  );
  let silverTrophies = member?.trophies?.filter(
    (trophy) => Number(trophy?.user_position) === 2
  );
  let bronzeTrophies = member?.trophies?.filter(
    (trophy) => Number(trophy?.user_position) === 3
  );
  let medals = member?.competitions;

  if (disciplines.length) {
    goldenTrophies = goldenTrophies.filter((trophy) =>
      disciplines.includes(trophy.discipline)
    );
    silverTrophies = silverTrophies.filter((trophy) =>
      disciplines.includes(trophy.discipline)
    );
    bronzeTrophies = bronzeTrophies.filter((trophy) =>
      disciplines.includes(trophy.discipline)
    );
    medals = medals.filter((trophy) => disciplines.includes(trophy.discipline));
  }

  const goldScore = goldenTrophies?.length * multipliers.gold;
  const silverScore = silverTrophies?.length * multipliers.silver;
  const bronzeScore = bronzeTrophies?.length * multipliers.bronze;
  const medalsScore = medals?.length * multipliers.medal;

  return goldScore + silverScore + bronzeScore + medalsScore;
}

export function applyScoreToEachMember(members, disciplines) {
  return members?.map(function (member) {
    return { ...member, score: calcScore(member, disciplines) };
  });
}

// Removes all those trophies & medals that aren't from a discipline that the team has
export function filterNonDisciplineTrophiesAndMedalsFromAllUsers(
  members,
  teamDisciplines
) {
  members.forEach(function (member, i, arr) {
    member.trophies = member.trophies.filter(function (trophy) {
      return teamDisciplines.includes(trophy?.discipline);
    });

    member.competitions = member.competitions.filter(function (competition) {
      return teamDisciplines.includes(competition?.discipline);
    });

    console.log(member);

    arr[i] = member;
  });

  return members;
}

// FileReader with promises
export function readFile(file) {
  return new Promise(function (resolve, reject) {
    const fileReader = new FileReader();

    fileReader.onload = function () {
      resolve(fileReader.result);
    };

    fileReader.onerror = reject;

    fileReader.readAsDataURL(file);
  });
}

// Canvas toBlob with promises
function toBlob(canvas, filename, imageType) {
  console.log(canvas);

  return new Promise(function (resolve, reject) {
    canvas.toBlob(function (blob) {
      const file = new File([blob], filename, { type: imageType });
      console.log(file);
      resolve(file);
    }, imageType);
  });
}

// Set src to html img using promises
function imgSetSrc(img, src) {
  return new Promise(function (resolve, reject) {
    img.onload = function () {
      resolve({ height: img.height, width: img.width });
    };

    img.src = src;
  });
}

export async function removeExifImg(imgFile) {
  try {
    const resultSrc = await readFile(imgFile);
    const canvas = document.createElement("canvas");

    const img = new Image();

    const { width, height } = await imgSetSrc(img, resultSrc);

    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    const toUpload = toBlob(canvas, "pic.png", "image/png");
    return toUpload;
  } catch (err) {
    console.log(err);
    return imgFile;
  }
}
