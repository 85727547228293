import React from "react";

import "../../styles/app/Podium.scss";
import DisplayProfilePic from "./DisplayProfilePic";

import scoreIcon from "../../images/icons/Score.svg";
import { individualUsersFrontUrl } from "../../js/context";

function PodiumUser({ member, hasCrown = false }) {
  return (
    <a href={`${individualUsersFrontUrl}/shelf/${member?.username}`} target="_blank"
      rel="noreferrer" alt="" className="PodiumUser">
      <div className="PodiumUser__profile-pic-wrapper">
        <DisplayProfilePic className="PodiumUser__profile-pic" userData={member} />
      </div>
      <h2 className="PodiumUser__username">{member?.username}</h2>
      <span className="PodiumUser__score-pill"><img style={{ marginRight: ".2rem", transform: "translateY(.05rem)" }} src={scoreIcon} />{member?.score}</span>
    </a>
  );
}

export default function Podium({ members }) {
  if (members?.length !== 3) {
    return;
  }

  return (
    <div className="Podium">
      <div className="Podium__base Podium__base--second">
        <span className="Podium__position">2nd</span>
        <PodiumUser member={members[1]} />
      </div>
      <div className="Podium__base Podium__base--first">
        <span className="Podium__position">1st</span>
        <PodiumUser member={members[0]} hasCrown={true} />
      </div>
      <div className="Podium__base Podium__base--third">
        <span className="Podium__position">3rd</span>
        <PodiumUser member={members[2]} />
      </div>
    </div>
  );
}
