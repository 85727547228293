import React, { useContext, useState } from "react";
import langs from "../../../langs";
import "../../styles/app/ShelfSwitch.scss";
import { LangContext } from "../App";

export default function ShelfSwitch({ setShelfToDisplay }) {
  const lang = useContext(LangContext);
  const [status, setCurrentStatus] = useState(0);

  function toggleStatus(newStatus) {
    setCurrentStatus(newStatus);
    setShelfToDisplay(newStatus);
  }

  return (
    <div className="ShelfSwitch">
      <button
        onClick={() => toggleStatus(0)}
        className={`ShelfSwitch__button ${
          status === 0 ? "ShelfSwitch__button--active" : ""
        }`}
      >
        {langs[lang]?.shelf?.switch_trophies ?? "Trophies"}
      </button>
      <button
        onClick={() => toggleStatus(1)}
        className={`ShelfSwitch__button ${
          status === 1 ? "ShelfSwitch__button--active" : ""
        }`}
      >
        {langs[lang]?.shelf?.switch_competitions ?? "All results"}
      </button>
      <div
        style={{ transform: `translateX(${status * 100}%)` }}
        className="ShelfSwitch__active-element-background"
      ></div>
    </div>
  );
}
