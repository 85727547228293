import React, { useState } from "react";
import "../../styles/ui/Popup.scss";

// Creates a popup
// Atm we are using the reactjs-popup package
export default function Popup({
  children,
  onHide = () => {},
  trigger = "",
  defaultDisplay = false,
}) {
  const [display, setDisplay] = useState(defaultDisplay);
  function hidePopup(e) {
    e.stopPropagation();
    if (e.currentTarget !== e.target) return;
    setDisplay(false);
    onHide();
  }

  function showPopup() {
    setDisplay(true);
  }

  const popup = display ? (
    <div className="popup-wrapper" onPointerUp={hidePopup}>
      <div className="Popup" trigger={trigger}>
        {children}
      </div>
    </div>
  ) : (
    ""
  );

  return (
    <>
      {popup}
      <div onPointerUp={showPopup}>{trigger}</div>
    </>
  );
}
