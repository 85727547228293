import React, { useEffect } from "react";

export default function PushNotificationButton() {
  async function loadWorker() {
    await navigator.serviceWorker.register("./notifs-worker.js");
  }

  async function subscribe() {
    const sw = await navigator.serviceWorker.ready;

    const subscription = await sw.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
      "BDIG0TnZbSVb6nP3xNIyCzThHv-fLb66A-uhqCXusVzxYWLHgXKLD0kIxiDF7C2pqB5W0ISRmR_Iku6dBGjAius",
    });

    console.log(JSON.stringify(subscription));
  }

  useEffect(() => {
    loadWorker();
  }, []);

  return <button onClick={subscribe}>Enable Push Notifications</button>;
}
