import React from "react";
import "../../styles/ui/Loader.scss";

import logo from "../../images/logo.png";

function Loader() {
  return (
    <div className="Loader">
      {/* <div className="Loader__spin"></div> */}
      <img alt="Mitali Logo" src={logo} className="Loader__img" />
    </div>
  );
}

export default Loader;
