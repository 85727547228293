import React, { useContext, useEffect, useState } from "react";

import noMembersFoundImg from "../../images/Add Team Member_Illustration.svg";

import "../../styles/app/MembersList.scss";
import langs from "../../../langs";
import {
  LangContext,
  UserDataContext,
  UserTypeContext,
  userTypes,
} from "../App";
import Button from "../ui/Button";

import addTeamMembersIcon from "../../images/icons/Add Team Members.svg";
import TeamMember from "./TeamMember";
import Popup from "../ui/Popup";
import axios from "axios";
import { apiUrl } from "../../js/context";
import {
  applyScoreToEachMember,
  createAuthHeaders,
  getLocalKey,
  getSession,
  getUserId,
} from "../../js/functions";
import Podium from "./Podium";

export default function MembersRanking(props) {
  const lang = useContext(LangContext);
  const userData = useContext(UserDataContext);
  const [members, setMembers] = useState([]);
  const noDisplayPodiumWithLessMembersThan = 10;


  useEffect(
    function () {
      let members = applyScoreToEachMember(
        props?.userData?.members,
        userData?.disciplines
      )?.sort(function (m1, m2) {
        return m2?.score - m1?.score;
      });
      setMembers(members);
    },
    [props, setMembers]
  );

  const noMembersComponent = (
    <>
      <img
        className="MembersList__no-members-found-pic"
        alt="No members found"
        src={noMembersFoundImg}
      />

      <h3 className="MembersList__no-members-found-text">
        {langs[lang]?.members_list?.no_members_visitor ??
          langs["es"]?.members_list?.no_members_visitor}
      </h3>
    </>
  );

  if (!members || members?.length === 0) {
    return noMembersComponent;
  }

  // If there are less than the minimum number of members
  // We won't display the podium
  if (members.length <= noDisplayPodiumWithLessMembersThan) {
    return (
      <section className="MembersList">
        {members?.map((memberData, i) => {
          return (
            <TeamMember
              memberPos={i + 1}
              showScore={true}
              isLast={i === members.length - 1}
              memberData={memberData}
            />
          );
        })}
      </section>
    );
  }

  return (
    <>
      <Podium members={members.slice(0, 3)} />
      <section className="MembersList">
        {members?.slice(3)?.map((memberData, i) => {
          return (
            <TeamMember
              memberPos={i + 1 + 3}
              showScore={true}
              isLast={i === members?.slice(3)?.length - 1}
              memberData={memberData}
            />
          );
        })}
      </section>
    </>
  );
}
