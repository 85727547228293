import React, { useContext, useEffect, useState } from "react";

import noMembersFoundImg from "../../images/Add Team Member_Illustration.svg";

import "../../styles/app/MembersList.scss";
import langs from "../../../langs";
import { LangContext, UserTypeContext, userTypes } from "../App";
import Button from "../ui/Button";

import addTeamMembersIcon from "../../images/icons/Add Team Members.svg";
import TeamMember from "./TeamMember";
import Popup from "../ui/Popup";
import axios from "axios";
import { apiUrl } from "../../js/context";
import {
  createAuthHeaders,
  getLocalKey,
  getSession,
  getUserId,
} from "../../js/functions";

export default function MembersList(props) {
  const lang = useContext(LangContext);
  const userType = useContext(UserTypeContext);
  const [members, setMembers] = useState([]);
  const { setShowPopup, setInviteLink } = props;

  useEffect(
    function () {
      if (!props?.userData?.members?.length) {
        return;
      }

      setMembers(props?.userData?.members);
    },
    [props, setMembers]
  );

  console.log(members);

  async function handleInviteMembersButtonClick() {
    try {
      const authHeaders = createAuthHeaders(getSession());

      const { data } = await axios.post(
        apiUrl + "/get-join-team-link",
        {
          teamId: getUserId(),
          _id: getUserId(),
        },
        { headers: authHeaders }
      );

      setInviteLink(data.link);
      setShowPopup(true);
    } catch (err) {
      alert("No se ha podido obtener el enlace...");
    }
  }

  const noMembersOwnerView = (
    <>
      <img
        className="MembersList__no-members-found-pic"
        alt="No members found"
        src={noMembersFoundImg}
      />

      <h3 className="MembersList__no-members-found-text">
        {langs[lang]?.members_list?.no_members ??
          langs["es"]?.members_list?.no_members}
      </h3>

      <Button
        style={{ fontSize: "1rem", padding: ".8rem" }}
        typeStyle="block"
        onClick={handleInviteMembersButtonClick}
      >
        <img
          style={{ height: "1.1rem", display: "block" }}
          src={addTeamMembersIcon}
          alt=""
        />
        {langs[lang]?.members_list?.add_team_members ??
          langs["es"]?.members_list?.add_team_members}
      </Button>
    </>
  );

  const noMembersVisitorView = (
    <>
      <img
        className="MembersList__no-members-found-pic"
        alt="No members found"
        src={noMembersFoundImg}
      />

      <h3 className="MembersList__no-members-found-text">
        {langs[lang]?.members_list?.no_members_visitor ??
          langs["es"]?.members_list?.no_members_visitor}
      </h3>
    </>
  );

  const noMembersComponent =
    userType === userTypes.owner ? noMembersOwnerView : noMembersVisitorView;

  return (
    <section className="MembersList">
      {(!members || members?.length === 0) && noMembersComponent}

      {(members?.length && userTypes.owner === userType) ? (
        <Button
          style={{ fontSize: "1rem", padding: ".8rem" }}
          typeStyle="block"
          onClick={handleInviteMembersButtonClick}
        >
          <img
            style={{ height: "1.1rem", display: "block" }}
            src={addTeamMembersIcon}
            alt=""
          />
          {langs[lang]?.members_list?.add_team_members ??
            langs["es"]?.members_list?.add_team_members}
        </Button>
      ) : <></>}
      {members?.map((memberData, i) => {
        return (
          <TeamMember
            showExpel={true}
            isLast={i === members.length - 1}
            memberData={memberData}
          />
        );
      })}
    </section>
  );
}
