import React, { useContext, useState } from "react";
import "../../styles/app/Claps.scss";
import { UserDataContext, UserTypeContext, userTypes } from "../App";
import axios from "axios";
import { apiUrl } from "../../js/context";

export default function Claps({ clapsNum, setClapsNum }) {
  const userData = useContext(UserDataContext);
  const userType = useContext(UserTypeContext);
  const [clapped, setClapped] = useState(false);

  async function storeClapDB() {
    await axios.post(
      apiUrl + "/add-clap-team",
      { _id: userData._id },
      { "Content-Type": "application/json" }
    );
  }

  function handleClick() {
    setClapsNum(clapsNum + 1);
    setClapped(true);
    storeClapDB();
  }

  return (
    <>
      {userType === userTypes.visitor && (
        <div
          className={`clap-button-wrapper ${clapped ? "clap-button-wrapper--clapped" : ""
            }`}
        >
          {/* <button
            onClick={!clapped ? handleClick : undefined}
            className={`ClapButton`}
          >
            <img
              className="ClapButton__icon"
              src="/images/hands-clapping.svg"
              alt="Clap"
            />
          </button> */}
        </div>
      )}
    </>
  );
}
