import React, { useContext } from "react";
import { NavLink, useParams } from "react-router-dom";

import membersIcon from "../../images/icons/Members_Inactive.svg";
import trophiesIcon from "../../images/icons/Trophies_Inactive.svg";
import rankingIcon from "../../images/icons/Ranking_Inactive.svg";
import langs from "../../../langs";
import { LangContext } from "../App";

import "../../styles/app/BottomNav.scss";

export default function BottomNav() {
  const lang = useContext(LangContext);
  const { profileId } = useParams();

  return (
    <nav className="BottomNav">
      <ul className="BottomNav__ul">
        <li className="BottomNav__li">
          <NavLink className={({ isActive }) => isActive ? "nav-link nav-link--clicked" : "nav-link"} to={`/members/${profileId}`}>
            <img className="nav-link__icon" alt="Members" src={membersIcon} />
            <span className="nav-link__span">
              {langs[lang]?.navbar?.members ?? langs["es"]?.navbar?.members}
            </span>
          </NavLink>
        </li>
        <li className="BottomNav__li">
          <NavLink className={({ isActive }) => isActive ? "nav-link nav-link--clicked" : "nav-link"} to={`/shelf/${profileId}`}>
            <img className="nav-link__icon" alt="Trophies" src={trophiesIcon} />
            <span className="nav-link__span">
              {langs[lang]?.navbar?.trophies ?? langs["es"]?.navbar?.trophies}
            </span>
          </NavLink>
        </li>
        <li className="BottomNav__li">
          <NavLink className={({ isActive }) => isActive ? "nav-link nav-link--clicked" : "nav-link"} to={`/ranking/${profileId}`}>
            <img className="nav-link__icon" alt="Ranking" src={rankingIcon} />
            <span className="nav-link__span">
              {langs[lang]?.navbar?.ranking ?? langs["es"]?.navbar?.ranking}
            </span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
