import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { FiChevronLeft } from "react-icons/fi";
import ProfilePic from "../ui/ProfilePic";
import { Link } from "react-router-dom";
import ChooseDisciplines from "../ui/ChooseDisciplines";
import {
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Chip,
  Tooltip,
} from "@mui/material";
import Notification from "../ui/Notification";
import useFirstRender from "../../js/useFirstRender";
import uuid from "react-uuid";
import {
  capitalizeFirstLetter,
  createAuthHeaders,
  fetchUserData,
  getUserId,
  getTeamNickname,
} from "../../js/functions";
import { colors } from "../../../theme";
import Centerer from "../ui/Centerer";
import {
  apiUrl,
  fallbackDisciplines,
  selectStyles,
  usernameRegex,
} from "../../js/context";
import PicUploader from "../ui/PicUploader";
import { frontUrl } from "../../js/context";
import langs from "../../../langs";
import Popup from "../ui/Popup";
import logoutIcon from "../../images/icons/LogOut.svg";
import Select from "react-select";

import "../../styles/app/Settings.scss";
import { LangContext, LoggedUserKey, UserDataContext } from "../App";
import ValidateMuiTextField from "../ui/ValidateTextField";
import Loader from "../ui/Loader";
import PushNotificationButton from "../ui/PushNotificationButton";
import Button from "../ui/Button";
import goBackIcon from "../../images/icons/Back Arrow.svg";
import downChevronIcon from "../../images/icons/Down Arrow.svg";

console.warn = console.error = () => { };

const defaultLang = "en";

// A switch with a well-placed label
function SwitchInput({ label, prop, setValue, defaultChecked }) {
  const [internalValue, setInternalValue] = useState(defaultChecked ?? false);

  function handleToggle(e) {
    const newValue = e.target.checked;
    setInternalValue(newValue);
    setValue(prop, newValue);
  }

  return (
    <div className="SetSetting">
      <label className="switch-label">
        {label}
        <Switch onChange={handleToggle} checked={internalValue} />
      </label>
    </div>
  );
}

function SelectLanguage({ defaultValue, prop, setValue }) {
  const lang = useContext(LangContext);

  function change(e) {
    console.log(e);
    setValue(prop, e?.value);
  }

  const options = [
    { value: "es", label: "Español" },
    { value: "en", label: "English" },
    { value: "ca", label: "Català" },
  ];

  const IndicatorSeparator = function () { };

  return (
    <Select
      aria-label={defaultValue}
      labelId="select-language-label"
      id="select-language"
      label="Idioma"
      options={options}
      components={{ IndicatorSeparator }}
      defaultValue={defaultValue}
      styles={selectStyles}
      onChange={change}
      placeholder={
        options.find((opt) => opt?.value === defaultValue)?.label ?? "Idioma"
      }
      separatorIndicator={false}
    />
  );
}

function ChooseLabels({
  defaultValue,
  setValue,
  prop,
  className = "",
  allPossibleValues = [],
}) {
  const lang = useContext(LangContext);
  const [newLabel, setNewLabel] = useState("");

  const buttonDisabled = !newLabel.length;

  function handleDelete(index) {
    let newValue = defaultValue;
    newValue.splice(index, 1);
    setValue(newValue);
  }

  function handleAdd() {
    if (newLabel.length === 0) {
      alert(
        langs[lang]?.settings.new_discipline_error_is_empty ??
        langs[defaultLang]?.settings.new_discipline_error_is_empty
      );
      return;
    }

    if (defaultValue.includes(newLabel)) {
      alert(
        langs[lang]?.settings.new_discipline_error_repeated ??
        langs[defaultLang]?.settings.new_discipline_error_repeated
      );
      return;
    }

    if (allPossibleValues.length && !allPossibleValues.includes(newLabel)) {
      alert(
        langs[lang]?.settings.new_discipline_error_unavailable ??
        langs[defaultLang]?.settings.new_discipline_error_unavailable
      );
      return;
    }

    let newValue = defaultValue;
    newValue.push(newLabel);
    setNewLabel("");
    setValue(newValue);
  }

  function handleInputChange(e) {
    const newInputValue = e.target.value;
    setNewLabel(newInputValue);
  }

  try {
    const listId = `${prop}-list`;
    const labels = defaultValue.map((label, index) => (
      <li className="ChooseLabels__label">
        <Chip
          label={capitalizeFirstLetter(label)}
          index={index}
          key={uuid()}
          variant="soft"
          style={{ backgroundColor: `${colors.mainColor}70` }}
          onDelete={() => handleDelete(index)}
        />
      </li>
    ));

    return (
      <>
        <div className={`ChooseLabels ${className}`}>
          <datalist style={{ display: "none" }} id={listId}>
            {allPossibleValues.map((value) => (
              <option key={uuid()} value={value} />
            ))}
          </datalist>
          <ul className="ChooseLabels__labels-list">{labels}</ul>
          <div className="ChooseLabels__add-label">
            <input
              className="ChooseLabels__add-label-input"
              value={newLabel}
              onChange={handleInputChange}
              placeholder={
                langs[lang]?.settings?.write_discipline ??
                langs[defaultLang]?.settings?.write_discipline
              }
              list={listId}
            />
            <Button
              className="ChooseLabels__add-label-button"
              variant="contained"
              onClick={handleAdd}
              disabled={buttonDisabled}
            >
              {langs[lang]?.settings?.add_discipline ??
                langs[defaultLang]?.settings?.add_discipline}
            </Button>
          </div>
        </div>
      </>
    );
  } catch (err) {
    console.error(err);
  }
}

export function SelectDisciplines({
  defaultValue,
  setValue,
  prop,
  className = "",
}) {
  const [disciplines, setDisciplines] = useState([]);
  async function getDisciplines() {
    try {
      const URL = `${apiUrl}/disciplines`;
      const { data: fetchedDisciplines } = await axios.get(URL);
      setDisciplines(fetchedDisciplines);
    } catch (err) {
      setDisciplines(fallbackDisciplines);
      console.log(err);
    }
  }

  useEffect(function () {
    getDisciplines();
  }, []);

  return (
    <ChooseLabels
      defaultValue={defaultValue}
      setValue={setValue}
      prop={prop}
      className={className}
      allPossibleValues={disciplines}
    />
  );
}

// All the settings related with userData
/*
- Picture
- Username
- Full name
- Disciplines
- Display language
- Birthday?
*/
function UserDataSettings({ data, setData, inputAlreadyUsed }) {
  const lang = useContext(LangContext);

  console.log(data)

  // If there's no data, wait and load
  if (!data.nickname) {
    return <Loader />;
  }

  try {
    const { profile_pic: profilePic, username, full_name, disciplines } = data;

    function showDefaultBannerPic(e) {
      const img = e.target;
      img.src = "/images/default-banner-pexels-liger-pham-1108701.jpg";
    }

    const imageUrl = apiUrl + "/images/banners-teams/" + data._id;
    return (
      <div className="settings-section" style={{ marginBottom: "1.5rem" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1.5rem",
          }}
        >
          <PicUploader style={{ zIndex: 2 }} action="/upload-profile-pic-teams">
            <ProfilePic
              size="big"
              userId={data._id}
              imageURL={frontUrl + "/images/default-profile.png"}
            />
          </PicUploader>

          <PicUploader className="upload-banner" action="/upload-banner-teams">
            <img
              alt=""
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "5px",
              }}
              onError={showDefaultBannerPic}
              src={imageUrl}
            />
          </PicUploader>
        </div>
        <ColorPickers userData={data} setUserData={setData} />
      </div>
    );
  } catch (err) {
    console.log(err);
  }
}

// All the settings related with userData
/*
- Language
- Dark mode
*/
function MiscSettings({
  data,
  userData,
  setLocalData,
  setRemoteData,
  nextPhase,
}) {
  const lang = useContext(LangContext);
  function handleLocalUpdate(key, newValue) {
    const newData = { ...data, [key]: newValue };
    setLocalData(newData);
  }

  function handleRemoteUpdate(key, newValue) {
    const newData = { ...userData, [key]: newValue };
    console.log(newData);
    setRemoteData(newData);
  }

  // If there's no data, wait and load
  /*  if (!data.languaje) {
    return <Loader />;
  } */

  try {
    let { language } = data;
    const disciplines = userData?.disciplines;

    console.log(disciplines)


    language = language ?? "es";
    return (
      <div className="settings-section" style={{ marginBottom: "3.5rem" }}>
        <label className="settings-label">
          <span className="settings-label__text">
            {langs?.[lang]?.settings?.disciplines ??
              langs?.["es"]?.settings?.disciplines}
          </span>
          <ChooseDisciplines
            defaultValue={disciplines}
            prop="disciplines"
            setValue={(newValue) => handleRemoteUpdate("disciplines", newValue)}
          />
        </label>

        <label>
          <span className="settings-label__text">
            {langs?.[lang]?.settings?.language ??
              langs?.["es"]?.settings?.language}
          </span>

          <SelectLanguage
            defaultValue={language}
            prop="language"
            setValue={handleLocalUpdate}
          />
        </label>
      </div>
    );
  } catch (err) {
    console.log(err);
  }
}

const presetColors = {
  shelf_color: [
    "#0A94D5",
    "#42f554",
    "#f542cb",
    "#f56942",
    "#5a42f5",
    "#1d4726",
    "#c7b516",
    "#26102b",
    "#bf3d2c",
    "#111111",
    "#9e9e9e",
  ],

  background_color: [
    "#5dc4f5",
    "#87ed91",
    "#ffbaef",
    "#ebb2a2",
    "#9084e3",
    "#659e71",
    "#e8dd79",
    "#9b62a8",
    "#a67d77",
    "#525252",
    "#e6e6e6",
  ],
};

const presetTextures = {
  background_color: [
    "pexels-aaron-kittredge-3624339.jpg",
    "pexels-matthias-fiesel-9280986.jpg",
    "pexels-anna-nekrashevich-8516782.jpg",
    "pexels-monstera-production-7794441.jpg",
    "pexels-ekaterina-belinskaya-4744827.jpg",
    "pexels-pixabay-220182.jpg",
    "pexels-fwstudio-129731.jpg",
    "pexels-pixabay-235986.jpg",
    "pexels-fwstudio-172289.jpg",
    "pexels-pixabay-326347.jpg",
    "pexels-fwstudio-172291.jpg",
    "pexels-scott-webb-2824173.jpg",
    "pexels-laura-tancredi-7078052.jpg",
    "pexels-street-donkey-3705687.jpg",
    "pexels-laura-tancredi-7078619.jpg",
  ],
};

function SettingsColorPicker({
  label,
  name,
  userData,
  style,
  setUserData,
  allowTextures = true,
}) {
  const [colorTexture, setColorTexture] = useState(userData[name]);
  const [previewTexture, setPreviewTexture] = useState(undefined);
  const [palette, setPalette] = useState("colors");

  useEffect(
    function () {
      if (userData[name]) setColorTexture(userData[name]);
    },
    [userData, name]
  );

  // Check if background is an hex color or a pic url
  const background = /^#(?:[0-9a-fA-F]{3}){1,2}$/.test(colorTexture)
    ? colorTexture
    : previewTexture ?? colorTexture;

  const trigger = (
    <div className="SettingsColorPicker" style={style}>
      {label}{" "}
      <button className="SettingsColorPicker__button">
        <div
          className="SettingsColorPicker__sample"
          style={{ background }}
        ></div>
      </button>
    </div>
  );

  const texturePalette =
    palette === "textures" ? "choose-colors-textures--textures" : "";

  let picsPalette = [];

  function handleChangeColorTexture(newColor, preview) {
    const newValue = { ...userData, [name]: newColor };
    setUserData(newValue);
    setColorTexture(newColor);
    if (!preview) return;
    setPreviewTexture(preview);
  }

  try {
    presetTextures[name ?? "background_color"].forEach((pic) => {
      const thumbnailPath = frontUrl + "/images/textures/thumbnails/";
      const path = "/images/textures/" + name + "/";
      // We'll save the picUrls also with the url() structure
      const thumbnailUrl = `url(${thumbnailPath + pic}) no-repeat fixed center`;
      const picUrl = `url(${path + pic})`;

      picsPalette.push(
        <button
          className="choose-colors-textures__color-texture"
          style={{ background: thumbnailUrl }}
          onClick={() => handleChangeColorTexture(picUrl, thumbnailUrl)}
        ></button>
      );
    });
  } catch (err) { }

  return (
    <Popup trigger={trigger}>
      <div className={`choose-colors-textures ${texturePalette}`}>
        <nav className="choose-colors-textures__nav">
          {allowTextures && (
            <div className="choose-colors-textures__nav-selected"></div>
          )}
          <Button
            variant="text"
            onClick={() => setPalette("colors")}
            className="choose-colors-textures__nav-btn"
          >
            Colors
          </Button>
          {allowTextures && (
            <Button
              variant="text"
              onClick={() => setPalette("textures")}
              className="choose-colors-textures__nav-btn"
            >
              Textures
            </Button>
          )}
        </nav>
        <div className="choose-colors-textures__grid">
          <div className="choose-colors-textures__grid-container choose-colors-textures__grid-container--colors">
            {presetColors[name]?.map((color) => (
              <button
                className="choose-colors-textures__color-texture"
                style={{ backgroundColor: color }}
                onClick={() => handleChangeColorTexture(color)}
              ></button>
            ))}
          </div>
          <div className="choose-colors-textures__grid-container choose-colors-textures__grid-container--textures">
            {picsPalette}
          </div>
        </div>
      </div>
    </Popup>
  );
}

function ColorPickers({ userData, setUserData }) {
  const lang = useContext(LangContext);

  return (
    <section className="ColorPickers">
      <SettingsColorPicker
        label={langs[lang]?.settings?.shelf_color ?? "Shelf color"}
        name="shelf_color"
        setUserData={setUserData}
        userData={userData}
        allowTextures={false}
        style={{ padding: 0, margin: 0, marginTop: ".7rem" }}
      />
    </section>
  );
}

export default function Settings({
  profilePicState,
  optionsState,
  nextPhase,
  startTutorial,
  handleLogout,
}) {
  const lang = useContext(LangContext);
  const isFirstRender = useFirstRender();
  const [notif, setNotif] = useState(false);
  const [profilePic, setProfilePic] = profilePicState;
  const [options, setOptions] = optionsState;
  const [inputAlreadyUsed, setInputAlreadyUsed] = useState({});
  const loggedUserKey = useContext(LoggedUserKey);
  const userId = getUserId();
  const [userData, setUserData] = useState({});


  useEffect(() => {
    fetchUserData(userId, setUserData);
  }, [userId]);

  // If user reloads settings, we force fetching the userData again
  useEffect(() => {
    fetchUserData(userId, setUserData);
  }, []);

  function handleChangeProfilePic() {
    //
  }

  function showNotif() {
    return; // We won't show any notifs
  }

  async function saveSettings(via) {
    if (isFirstRender) return;

    if (via === "local") {
      localStorage.setItem("mitali", JSON.stringify(options));
      showNotif();
      return true;
    }

    if (via === "remote") {
      const headers = createAuthHeaders(loggedUserKey);

      try {
        const url = `${apiUrl}/edit-team`;
        if (!url) return;

        const {
          status,
          data: { already_used: alreadyUsed },
        } = await axios.post(
          url,
          { ...userData, ...{ authorization: loggedUserKey } },
          {
            headers,
            withCredentials: true,
          }
        );

        if (status !== 200) {
          //! Must give feedback
          throw new Error();
        }

        setInputAlreadyUsed({});
        if (alreadyUsed.length) {
          for (let field of alreadyUsed) {
            setInputAlreadyUsed({ ...inputAlreadyUsed, ...{ [field]: true } });
          }

          return;
        }

        setInputAlreadyUsed({});
        showNotif();
        return true;
      } catch (err) {
        console.log(err);
        console.log("Unable to get remote access to edit user");
      }
    }

    return false;
  }

  useEffect(() => {
    saveSettings("local");
  }, [options]);

  useEffect(() => {
    saveSettings("remote");
  }, [userData, profilePic]);

  if (!userData) {
    fetchUserData(userId, setUserData);
    return <Loader />;
  }

  return (
    <>
      <nav className="settings-nav">
        <Link to={`/shelf/${getTeamNickname()}`}>
          <Button typeStyle="gray">
            <img src={goBackIcon} alt="" className="icon" />
          </Button>
        </Link>
        <h1 className="settings-nav__title">
          {langs[lang]?.settings.settings ??
            langs[defaultLang]?.settings.settings}
        </h1>
      </nav>
      <section style={{ paddingTop: "1.5rem" }} className="Settings with-nav">
        <UserDataSettings
          data={userData}
          setData={setUserData}
          inputAlreadyUsed={inputAlreadyUsed}
        />

        <MiscSettings
          data={options}
          setLocalData={setOptions}
          setRemoteData={setUserData}
          nextPhase={nextPhase}
          userData={userData}
        />

        <Centerer>
          <button className="logout-btn" onClick={handleLogout} variant="text">
            <img src={logoutIcon} alt="" />
            {langs[lang]?.settings.logout ??
              langs[defaultLang]?.settings.logout}
          </button>
        </Centerer>

        <Centerer>
          <a
            className="link"
            style={{ marginTop: "1rem", fontSize: ".8rem" }}
            href="https://mitali.app/privacy.html"
          >
            {langs[lang]?.settings.privacy ??
              langs[defaultLang]?.settings.privacy}
          </a>
        </Centerer>
      </section>
      {notif && <Notification>Options updated</Notification>}
    </>
  );
}
