import React from "react";
import ImageWithFallback from "../ui/ImageWithFallback";
import { apiUrl } from "../../js/context";

export default function DisplayProfilePic(props) {

    const imagesDirectory = apiUrl + "/images/profile-pic/";
    const defaultUrl = "/images/default-profile.png";
    let imageUrl;

    imageUrl = imagesDirectory + props?.userData?._id;

    return <ImageWithFallback {...props} defaultUrl={defaultUrl} imageUrl={imageUrl} />
}