import React, { useEffect, useState } from "react";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";

export default function CountrySelect({
  onChange,
  required,
  label = "Country",
}) {
  const [countries, setCountries] = useState([]);

  async function fetchCountries() {
    const URL = "https://restcountries.com/v3.1/all?fields=name";
    const { data: countriesInfo } = await axios.get(URL);
    const countriesNames = countriesInfo
      .map((country) => {
        /* Get the key of every "nativeName" value object
      (the key it's an abbreviation of the lang talked in the country) */
        const nativeNameObject = country.name.nativeName;

        /* If there is more than one lang
      in the country, we display the name in English */
        const countryLangsKey = Object.keys(nativeNameObject);
        if (countryLangsKey.length > 1) {
          // Common name in the API is the name in English
          return country.name.common;
        }

        const [countryLangKey] = countryLangsKey;
        const nativeNames = nativeNameObject[countryLangKey];
        if (!nativeNames) return "";
        const { common: nativeName } = nativeNames;
        return nativeName;
      })
      .filter((countryName) => countryName.length);

    const sortedCountriesNames = countriesNames.sort((a, b) =>
      a.localeCompare(b)
    );
    setCountries(sortedCountriesNames);
  }

  useEffect(function () {
    fetchCountries();
  }, []);

  return (
    <Autocomplete
      disablePortal
      id="country"
      required={required}
      name="country"
      options={countries}
      onChange={onChange}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField {...params} label={label} required={required} />
      )}
    />
  );
}
