import React, { useState } from "react";
import "../../styles/ui/Popup.scss";

// Creates a popup
// Atm we are using the reactjs-popup package
// This popup is displayed/hidden using outer states
export default function ManualPopup({
  children,
  onHide = () => {},
  trigger = "",
  display = false,
  setDisplay
}) {
  function hidePopup(e) {
    e.stopPropagation();
    if (e.currentTarget !== e.target) return;
    setDisplay(false);
    onHide();
  }

  function showPopup() {
    setDisplay(true);
  }

  const popup = display ? (
    <div className="popup-wrapper" onPointerUp={hidePopup}>
      <div className="Popup" trigger={trigger}>
        {children}
      </div>
    </div>
  ) : (
    ""
  );

  return (
    <>
      {popup}
      <div onPointerUp={showPopup}>{trigger}</div>
    </>
  );
}
