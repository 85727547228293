import React from "react";

import "../../styles/ui/InputText.scss";

export default function InputText(props) {

  return (
    <div className="input-text-wrapper">
      <label className="input-text-label">{props?.label}</label>

      <div className="InputText">
        {props?.icon && (
          <img src={props?.icon} alt="" className="InputText__icon" />
        )}
        <input {...props} className="InputText__input" />
      </div>
    </div>
  );
}
