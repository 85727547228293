import React, { useContext, useEffect, useState } from "react";
import {
  LangContext,
  ThemeContext,
  UserTypeContext,
  userTypes,
  LoggedUserKey,
  UserDataContext,
} from "../App";
import {
  capitalizeFirstLetter,
  formatUserRankingPosition,
  createAuthHeaders,
  getDateDiffInDays,
  humanFormatDate,
  createDateFromTrophyFormat,
  filterNonDisciplineTrophiesAndMedalsFromAllUsers,
} from "../../js/functions";
import Loader from "../ui/Loader";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../../styles/app/Shelf.scss";
import ChangeColor from "./ChangeColor";
import axios from "axios";

import langs from "../../../langs";
import {
  apiUrl,
  frontUrl,
  individualUsersFrontUrl,
  locale,
} from "../../js/context";
import ParallaxShelf from "../ui/ParallaxShelf";
import AskInstallApp from "../ui/AskInstallApp";
import FancyButton from "../ui/FancyButton";
import { FiEye } from "react-icons/fi";
import { FiEyeOff } from "react-icons/fi";
import { FaStar, FaRegStar } from "react-icons/fa";
import Button from "../ui/Button";

import medallaPic from "../../images/medalla.png";
import emptyStarIcon from "../../images/icons/Star.svg";
import fillStarIcon from "../../images/icons/Fill Star.svg";
import openEyeIcon from "../../images/icons/Open Eye.svg";
import closedEyeIcon from "../../images/icons/Close Eye.svg";
import shareIcon from "../../images/icons/Share.svg";
import YearPicker from "../ui/YearPicker";
import ImageWithFallback from "../ui/ImageWithFallback";
import DisplayProfilePic from "./DisplayProfilePic";

function convertDBDateIntoDate(datetime) {
  const datetimeType = (typeof datetime).toLocaleLowerCase();

  // Check if is string
  if (datetimeType === "string") {
    return new Date(datetime);
  }

  // Check if it's only year
  if (datetimeType === "number") {
    let datetimeStr = `${datetime}`;
    if (!datetimeStr.length === 4) {
      return new Date("01-01" + datetime);
    }

    // If not, we assume it's timestamp
    return new Date(datetime * 1000);
  }
}

function TrophyGlow({ size = "normal" }) {
  const sizeClass = size === "big" ? "TrophyGlow--big" : "";

  return (
    <div class={`TrophyGlow ${sizeClass}`}>
      <div className="TrophyGlow__aurea"></div>
      <div className="TrophyGlow__core"></div>
    </div>
  );
}

function TrophyOwner({ trophy, style }) {
  try {
    const { owner } = trophy;

    return (
      <a
        target="_blank"
        rel="noreferrer"
        href={`${individualUsersFrontUrl}/shelf/${owner?.username}`}
        style={style}
        className="TrophyOwner"
      >
        <DisplayProfilePic userData={owner} className="TrophyOwner__pic" />

        <span className="TrophyOwner__username">{owner.username}</span>
      </a>
    );
  } catch (err) {
    console.log(err);
  }
}

function MedalOwner(props) {
  return <TrophyOwner {...props} trophy={props?.medal} />;
}

function Trophy({
  data,
  shelfColor,
  size = "default",
  onPointerUp,
  children: shelf,
}) {
  try {
    let { competition_name, image, date: datetime } = data;
    image = `${frontUrl}${image}`;
    const classSize = "Trophy--" + size;
    const defaultImage = `${frontUrl}/images/trophies/1-gold.png`;
    const date = convertDBDateIntoDate(datetime);
    const todayDate = new Date();
    const isNewTrophy = getDateDiffInDays(date, todayDate) <= 7;
    let year = date.getFullYear();

    if (!image) {
      image = defaultImage;
    }

    if (!image.length) {
      image = defaultImage;
    }

    function addDefaultSrc({ currentTarget }) {
      currentTarget.onerror = null; // prevents looping
      currentTarget.src = defaultImage;
    }

    const classShine =
      Number(data.user_position) === 1 && !data?.hide ? "Trophy--shine" : "";

    return (
      <div
        className={`Trophy ${classSize} ${classShine} ${data?.highlighted && "Trophy--highlighted"
          }`}
        onPointerUp={onPointerUp}
      >
        <ParallaxShelf color={shelfColor}>
          <div className="Trophy__pic-wrapper">
            {isNewTrophy && <span className="Trophy__year-new-badge">new</span>}
            <img
              alt={`${competition_name} trophy`}
              className={`Trophy__pic ${data?.highlighted && "Trophy__pic--highlighted"
                }  ${data?.hide && "Trophy__pic--hidden"}`}
              src={image}
              onError={addDefaultSrc}
            />

            {data?.highlighted && !data?.hide && <TrophyGlow />}
          </div>
          <span className="Trophy__year-pill">{year !== 1970 && year}</span>
        </ParallaxShelf>
        <h2 className="Trophy__name">{competition_name}</h2>
        <TrophyOwner style={{ marginTop: ".5rem" }} trophy={data} />
        <div
          style={{ backgroundColor: shelfColor }}
          className="Trophy__after"
        ></div>
        {shelf}
      </div>
    );
  } catch (err) {
    console.log("Unable to render this trophy");
  }
}

function ShowTrophy({ data, highlightTrophy, toggleShowTrophy, shelfColor }) {
  const userTypeContextValue = useContext(UserTypeContext);

  let { competition_name, image, date: datetime, index, user_position } = data;

  console.log(data);

  // The only reason of this state is to update the component after a click
  const [buttonClicked, setButtonClicked] = useState(false);

  const userData = useContext(UserDataContext);
  const lang = useContext(LangContext);
  let date;
  const today = new Date();
  if ((typeof datetime).toLowerCase() === "string") {
    date = new Date(datetime);
  } else {
    date = new Date(datetime * 1000);
  }

  const isNewTrophy = getDateDiffInDays(date, today) <= 7;

  const formattedDate = date.toLocaleDateString(locale);
  const defaultImage = `${frontUrl}/images/trophies/1-gold.png`;

  if (!image) {
    image = defaultImage;
  }

  if (!image.length) {
    image = defaultImage;
  }

  function addDefaultSrc({ currentTarget }) {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src = defaultImage;
  }

  async function handleShare() {
    const shareUrl =
      frontUrl + "/shelf/" + userData?.username + "?show=trophy&id=" + index;

    if (navigator.share) {
      try {
        await navigator.share({
          title:
            langs[lang]?.popups?.share_trophy?.title ??
            langs["es"]?.popups?.share_trophy?.title,
          text:
            langs[lang]?.popups?.share_trophy?.text ??
            langs["es"]?.popups?.share_trophy?.text,
          url: shareUrl,
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        await navigator.clipboard.writeText(shareUrl);
        alert("Enlace copiado al portapapeles.");
      } catch (err) {
        console.log(err);
      }
    }
    console.log();
  }

  return (
    <div
      className={`ShowTrophy ${data?.highlighted && "ShowTrophy--highlighted"}`}
    >
      {isNewTrophy && (
        <span className="ShowTrophy__new-trophy">
          {langs?.[lang]?.popups?.new_trophy ??
            langs?.["es"]?.popups?.new_trophy}
        </span>
      )}
      <ParallaxShelf
        style={{ width: "70%", display: "block", margin: "auto" }}
        color={shelfColor ?? ""}
      >
        <img
          alt={`${competition_name} trophy`}
          className={`ShowTrophy__pic ${data?.hide && "ShowTrophy__pic--hidden"
            }`}
          src={image}
          onError={addDefaultSrc}
        />
        {data?.highlighted && !data?.hide && <TrophyGlow size="big" />}
      </ParallaxShelf>
      <h2 className="ShowTrophy__name">{competition_name}</h2>
      <h3 className="ShowTrophy__date">
        {humanFormatDate(formattedDate, lang)}
      </h3>
      <TrophyOwner
        style={{ transform: "translateY(-1rem) scale(1.2)" }}
        trophy={data}
      />
    </div>
  );
}

export function PopupTrophy({
  trophy,
  size = "default",
  display,
  setDisplay,
  highlightTrophy,
  toggleShowTrophy,
}) {
  const userData = useContext(UserDataContext);
  const shelfColor = userData?.shelf_color ?? defaultColors?.shelf;

  function hidePopup(e) {
    e.stopPropagation();
    if (e.currentTarget !== e.target) return;
    setDisplay(false);
  }
  // Create the button that will trigger the popup
  // Furthermore, such button will also show a preview of the trophy

  if (!display) {
    return <></>;
  }

  return (
    <>
      <div className="popup-wrapper" onPointerUp={hidePopup}>
        <div className="Popup">
          <div className="trophy_holder">
            <div className="trophy_holder__content-wrapper">
              <ShowTrophy
                key={JSON.stringify(trophy)}
                size="big"
                shelfColor={shelfColor}
                data={trophy}
                highlightTrophy={highlightTrophy}
                toggleShowTrophy={toggleShowTrophy}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const views = {
  allTrophies: "all-trophies",
  oneByOne: "one-by-one",
};

const defaultColors = {
  cupboard: "#1f0a0a",
  shelf: "#3f1e1e",
  background: "#ededed",
};

function Competition({
  data,
  highlightCompetition,
  toggleShowCompetition,
  isLast = false,
}) {
  const userData = useContext(UserDataContext);
  const userType = useContext(UserTypeContext);

  // A simple state updater
  const [buttonClicked, setButtonClicked] = useState(false);

  const buttonStyles = {
    fontSize: "1.3rem",
    marginBottom: ".2rem",
    color: "black",
    backgroundColor: "rgba(180, 180, 180, .1)",
  };

  try {
    const { competition_name, user_position } = data;
    return (
      <div className={`Competition ${!isLast && "Competition--not-last"}`}>
        <img
          className={`Competition__medal ${data?.highlighted &&
            !data?.hide &&
            "Competition__medal--highlighted"
            } ${data?.hide && "Competition__medal--hide"}`}
          src={medallaPic}
          alt="Medalla"
        />
        <div
          className={`Competition__info ${data?.highlighted && !data?.hide && "Competition__info--highlighted"
            } ${data?.hide && "Competition__info--hide"}`}
        >
          <span className={`Competition__name`}>{competition_name}</span>
          <span className="Competition__user-position">#{user_position}</span>
          <MedalOwner
            style={{ marginLeft: ".7rem", transform: "translateY(.25rem)" }}
            medal={data}
          />
        </div>
      </div>
    );
  } catch (err) { }
}

function CompetitionsShelf({
  shelfToDisplay,
  highlightCompetition,
  toggleShowCompetition,
  teamDisciplines,
  medals,
}) {
  const lang = useContext(LangContext);
  const userData = useContext(UserDataContext);
  const userType = useContext(UserTypeContext);

  try {
    // Sort medals by date. Doesn't matter if they're strings or dates, it'll work sorting same types (at least)
    medals = medals?.sort((compA, compB) => {
      const dateA = convertDBDateIntoDate(compA.date);
      const dateB = convertDBDateIntoDate(compB.date);

      return dateB - dateA;
    });

    const allMonths = [
      ...new Set(
        medals
          .map((medal) => {
            return convertDBDateIntoDate(medal?.date)?.getMonth?.();
          })
          .sort((a, b) => b - a)
      ),
    ];

    return (
      <div className="CompetitionsShelf">
        <div className="competitions-wrapper">
          {allMonths.map((month) => {
            const thisMonthMedals = medals?.filter(
              (medal) =>
                convertDBDateIntoDate(medal?.date).getMonth?.() === month
            );

            return (
              <div className="competitions-year">
                <h3 className="competitions-year__year">
                  {langs[lang]?.months[month]}
                </h3>

                <div className="competitions-year__background-wrapper">
                  <div className="competitions-year__background">
                    {thisMonthMedals?.map((medal, i) => {
                      return (
                        <Competition
                          isLast={i === thisMonthMedals.length - 1}
                          data={medal}
                          highlightCompetition={highlightCompetition}
                          toggleShowCompetition={toggleShowCompetition}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } catch (err) {
    console.log(err);
  }
}

// The Trophies list wrapper
// Currently, handles "shelf" view (all the trophies) (default), and gallery view (one by one)
function Shelf({
  nextPhase,
  shelfToDisplay,
  showTrophy,
  highlightCompetition,
  toggleShowCompetition,
  setShowTrophy,
}) {
  const userData = useContext(UserDataContext);
  const loggedUserKey = useContext(LoggedUserKey);
  const options = useContext(ThemeContext);
  const lang = useContext(LangContext);
  const userType = useContext(UserTypeContext);
  const [view, setView] = useState(views.allTrophies);
  const [trophies, setTrophies] = useState(undefined);
  const [sortedTrophies, setSortedTrophies] = useState(undefined);

  const teamDisciplines = userData?.disciplines?.map((disc) => disc.name);

  const now = new Date();
  const currentYear = now.getFullYear();

  //const minYear = createDateFromTrophyFormat(minYearTrophy?.date).getFullYear?.();
  let yearsArray = trophies?.map((trophy) =>
    convertDBDateIntoDate(trophy?.date)?.getFullYear?.()
  );
  const minYear = Math.min.apply(null, yearsArray);

  const [displayingYear, setDisplayingYear] = useState(currentYear);


  const thisYearTrophies = trophies
    ?.filter((trophy) => trophy?.date)
    .filter((trophy) => {
      let trophyDate = convertDBDateIntoDate(trophy?.date);
      return trophyDate.getFullYear() === displayingYear;
    });

  const shelfColor = userData.shelf_color ?? defaultColors.shelf;
  const backgroundColor = defaultColors.background;

  function loadTrophies() {
    if (!userData.members) return;

    setTrophies(
      userData?.members
        // We add to each trophy who is it's owner
        ?.map((member) =>
          member?.trophies?.map?.(function (trophy) {
            return { ...trophy, owner: member };
          })
        )
        ?.flat()
        ?.map((trophy, index) => {
          return { ...trophy, index: index };
        })
    );
  }

  useEffect(() => {
    if (!userData) return;
    loadTrophies();
  }, [userData]);

  useEffect(() => {
    sortTrophiesByDiscipline(thisYearTrophies);
  }, [trophies, displayingYear]);

  if (!userData) {
    return <Loader />;
  }

  function sortTrophiesByDiscipline(trophies) {
    if (!trophies) return;

    trophies = trophies.filter((trophy) => !trophy?.hide);

    let allDisciplines = [];
    for (let i = 0; i < trophies.length; i++) {
      try {
        const trophy = trophies[i];
        const { discipline } = trophy;
        if (!discipline) continue;
        if (!discipline.length) continue;
        if (allDisciplines.includes(discipline)) continue;
        allDisciplines.push(discipline);
      } catch (err) {
        console.log(err);
      }
    }

    let sortedTrophies = {};
    for (let i = 0; i < allDisciplines.length; i++) {
      const discipline = allDisciplines[i];
      const disciplineTrophies = trophies?.filter(
        (trophy) => trophy.discipline === discipline
      );

      sortedTrophies[discipline] = [...disciplineTrophies];
    }

    setSortedTrophies({ ...sortedTrophies });
  }

  let medals = userData?.members
    ?.map(
      (member) =>
        member.competitions
          ?.filter((comp) => comp)
          .map(function (comp) {
            return { ...comp, owner: member };
          }) ?? []
    )
    .flat();
  medals = medals?.filter((medal) => !medal?.hide);

  medals = medals?.filter(
    (medal) =>
      convertDBDateIntoDate(medal?.date)?.getFullYear?.() === displayingYear
  );

  // Save new color in the database
  async function saveSceneColor(key, newColor) {
    const headers = createAuthHeaders(loggedUserKey);

    const { status } = await axios.post(
      `${apiUrl}/edit-user`,
      { _id: userData._id, [key]: newColor },
      {
        headers: headers,
      }
    );

    return status === 200;
  }

  function handleChangeBackgroundColor(newColor) {
    /**
     * Extracts the rgb intensities of a hexadecimal color
     * @param {string} color Color in hexadecimal format (atm)
     * @returns {object}, following the interface below: {r, g, b}, each with its intensity in number
     */
    function extractRGB(color) {
      const rgb = ["red", "green", "blue"];
      const regex = new RegExp("^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$", "");
      // Check if color is hexadecimal
      if (regex.exec(color)) {
        color = color.replace(/#/gi, "");
        // If the color has only one digit per pixel
        if (color.length === 3) {
          const pixels = color.split("");
          let toReturn = {};
          for (let i = 0; i < pixels.length; i++) {
            const primaryColor = rgb[i];
            toReturn[primaryColor] = parseInt(pixels[i], 16);
          }

          return toReturn;
        }

        // If the color has 2 digits per pixel
        let toReturn = {};
        for (let i = 0; i < rgb.length; i++) {
          const primaryColor = rgb[i];
          const sliceIndex = (i + 1) * 2;
          const pixel = color.slice(sliceIndex - 2, sliceIndex);
          toReturn[primaryColor] = parseInt(pixel, 16);
        }

        return toReturn;
      }

      return false;
    }

    /**
     * Generates an hexadecimal from an array of three colors
     * @param {array} colors Intensities of the three pixels
     * @returns {string} The hexadecimal color
     */
    function generateHex(colors) {
      // Validate is a full array
      if (!colors[0]) return;

      const hexColors = colors.map((color) => color.toString(16));
      return `#${hexColors.join("")}`;
    }

    function generateRBGColor(red, green, blue) {
      return `rgb(${red}, ${green}, ${blue})`;
    }

    function checkColorBrightness(color) {
      const rgb = extractRGB(color);
      const { red, green, blue } = rgb;

      // If the color is too dark, make it lighter
      const threshold = 186;
      if (red * 0.299 + green * 0.587 + blue * 0.114 < threshold) {
        //alert("The color you've chosen is too dark, let's make it lighter!");
        const redGreenRatio = red / green;
        const redBlueRatio = red / blue;
        // Advanced math
        const newRed = Math.round(
          threshold /
          (0.299 + (1 / redGreenRatio) * 0.587 + (1 / redBlueRatio) * 0.114)
        );

        const newGreen = Math.round(newRed / redGreenRatio);
        const newBlue = Math.round(newRed / redBlueRatio);
        const newColor = generateRBGColor(newRed, newGreen, newBlue);
        return newColor;
      }

      return color;
    }

    const newBGColor = checkColorBrightness(newColor);
    saveSceneColor("background_color", newBGColor);
  }

  if (!trophies || !sortedTrophies) return <Loader />;
  if ((typeof trophies.map).toLowerCase() !== "function") return <Loader />;

  let sortedTrophiesAndOtherClassTheLastOne = Object.keys(sortedTrophies);
  sortedTrophiesAndOtherClassTheLastOne.push(
    sortedTrophiesAndOtherClassTheLastOne.splice(
      sortedTrophiesAndOtherClassTheLastOne.indexOf("Other"),
      1
    )[0]
  );

  return (
    <>
      {/* Shelf background is a fixed element to be able
      to modify the background color of the background
      (it acts like a pseudo background,
      that we can change diractly from the shelf component) */}
      <div className="shelves-frame">
        <div
          className="shelves-wrapper"
          style={{ transform: `translateX(-${shelfToDisplay * 50}%)` }}
        >
          <div className="shelf-wrapper">
            {userData?.members?.length > 0 && (
              <div className="Shelf">
                <YearPicker
                  year={displayingYear}
                  setYear={setDisplayingYear}
                  maxYear={currentYear}
                  minYear={minYear}
                />
                <div
                  style={{ background: backgroundColor }}
                  className="Shelf__background"
                ></div>
                {sortedTrophiesAndOtherClassTheLastOne.map((discipline) => {
                  const trophies = sortedTrophies[discipline];
                  return (
                    <>
                      {trophies?.length && (
                        <div className="Shelf__discipline-trophies-wrapper">
                          <h2 className="Shelf__discipline">
                            {langs[lang]?.disciplines[
                              discipline?.toLowerCase()
                            ] ?? capitalizeFirstLetter(discipline)}
                          </h2>
                          <div className="Shelf__discipline-sorted-trophies">
                            <div className="Shelf__background-wrapper">
                              <div className="Shelf__background"></div>
                            </div>

                            {/* We will iterate de dos en dos */}
                            {trophies &&
                              trophies
                                // Sort by date
                                ?.sort((a, b) => {
                                  // As dates could be different types, we make sure we convert them into actual dates to compare
                                  const aDate = convertDBDateIntoDate(a.date);
                                  const bDate = convertDBDateIntoDate(b.date);
                                  return bDate - aDate;
                                })
                                ?.map((trophy, trophyIndex) => {
                                  // We make the trophies appear in two columns
                                  if (trophyIndex % 2 !== 0) return "";
                                  const nextTrophy = trophies[trophyIndex + 1];

                                  return (
                                    <>
                                      <div className="trophies-row">
                                        {trophy && (
                                          <Trophy
                                            onPointerUp={() =>
                                              showTrophy(trophy)
                                            }
                                            shelfColor={shelfColor}
                                            size="default"
                                            key={JSON.stringify(trophy)}
                                            data={trophy}
                                          />
                                        )}
                                        {nextTrophy && (
                                          <Trophy
                                            onPointerUp={() =>
                                              showTrophy(nextTrophy)
                                            }
                                            shelfColor={shelfColor}
                                            size="default"
                                            key={JSON.stringify(nextTrophy)}
                                            data={nextTrophy}
                                          />
                                        )}
                                      </div>
                                    </>
                                  );
                                })}
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            )}

            <div className="competitions-wrapper">
              <CompetitionsShelf
                highlightCompetition={highlightCompetition}
                toggleShowCompetition={toggleShowCompetition}
                shelfToDisplay={shelfToDisplay}
                displayYear={displayingYear}
                teamDisciplines={teamDisciplines}
                medals={medals}
              />
            </div>

            {sortedTrophies &&
              JSON.stringify(sortedTrophies) === "{}" &&
              medals &&
              medals.length === 0 && (
                <h2 style={{ fontSize: "1rem", opacity: 0.7 }}>
                  {langs[lang]?.trophies?.no_trophies_no_medals}
                </h2>
              )}
          </div>

          <div className="competitions-wrapper">
            <CompetitionsShelf
              highlightCompetition={highlightCompetition}
              toggleShowCompetition={toggleShowCompetition}
              shelfToDisplay={shelfToDisplay}
              displayYear={displayingYear}
              teamDisciplines={teamDisciplines}
              medals={medals}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Shelf;
