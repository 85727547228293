const langs = {
  es: {
    install_app: "Instalar app",
    months: [
      "ENE",
      "FEB",
      "MAR",
      "ABR",
      "MAY",
      "JUN",
      "JUL",
      "AGO",
      "SEP",
      "OCT",
      "NOV",
      "DIC",
    ],
    login_register: {
      login_title: "Iniciar sesión",
      nickname:
        "Nickname de equipo (p. ej. el nombre de tu equipo sin espacios)",
      password: "Contraseña",
      forgot_password:
        "¿Has olvidado tu contraseña? Haz click aquí para restablecerla",
      login: "Entrar",
      new_to_mitali: "¿Todavía sin perfil de equipo?",
      register_title: "Crea tu cuenta de equipo",
      register_subtitle: "y lleva tu equipo al siguiente nivel",
      official_name: "Nombre del equipo",
      country: "País",
      disciplines: "Disciplinas",
      email: "Correo electrónico",
      privacy_1: "He leído y acepto las",
      privacy_2: "políticas de privacidad",
      create_account: "Crea tu cuenta",
      already_have_account: "¿Ya tienes una cuenta de equipo?",
      validation: {
        nickname_used: "Este nombre de equipo ya está en uso",
        nickname_error:
          "El nombre de equipo solo puede contener letras, números, puntos (.) y guiones bajos (_)",
        email_used: "Este correo electrónico ya está en uso.",
        email_error: "Este no es un correo electrónico válido",
        password_error:
          "La contraseña debe tener 7 caracteres mínimo, conteniendo números y letras.",
      },
    },
    reset_password: {
      title: "Restablecer contraseña",
      subtitle_link:
        "Escribe el email asociado a tu cuenta, para poderte enviar el enlace para cambiar de contraseña.",
      subtitle: "Elige una nueva contraseña",
      new_password: "Nueva contraseña",
      button_reset: "Restablecer la contraseña",
      button_go_back: "Volver",
      button_send_reset_link: "Enviar el enlace para cambiar la contraseña",
    },
    disciplines: {
      chess: "Ajedrez",
      running: "Correr",
      football: "Fútbol",
      "running & triathlon": "Run. & Tri.",
    },
    profile: {
      profile: "Perfil",
      claps: (claps) => (claps !== 1 ? "Claps" : "Clap"),
      trophies: (trophies) => (trophies?.length !== 1 ? "Trofeos" : "Trofeo"),
      medals: (medals) => (medals?.length !== 1 ? "Medallas" : "Medalla"),
      members: (members) => (members?.length !== 1 ? "Miembros" : "Miembro"),
    },
    shelf: {
      trophies: "Trofeos",
      share_button: "Comparte tu Shelf",
      create_own_button: "Crea tu Shelf",
      reset_colors: "Restablecer colores",
      switch_trophies: "Trofeos",
      switch_competitions: "Medallas",
    },
    claim_trophy: {
      title: "¿Nos hemos olvidado algún trofeo?",
      subtitle:
        "Para reclamar un trofeo que no aparezca, puedes rellenar este formulario.",
      competition_title: "Nombre de la competición",
      date: "Fecha de la competición",
      ranking_position: "Tu ranking (en tu categoría)",
      url: "La url de la clasificación (opcional)",
    },
    competitions: {
      title: "Medallas",
      description:
        "Por cada participación a una competición (aunque no termine en podio) creemos que igualmente te mereces una medalla",
      medals: (year, nickname, num) =>
        `En ${year}, ${nickname} ha conseguido ${num} ${num === 1 ? "medalla" : "medallas"
        }`,
    },
    settings: {
      settings: "Configuración",
      select_discipline: "Selecciona una disciplina",
      nickname: "Nombre de usuario",
      official_name: "Nombre completo",
      disciplines: "Disciplinas",
      shelf_color: "Color de la estantería",
      background_color: "Color del fondo",
      language: "Idioma",
      dark_mode: "Modo oscuro",
      preview: "Vista previa",
      display_tutorial: "Mostrar tutorial",
      privacy: "Privacidad",
      new_discipline_error_is_empty: "Debes elegir una disciplina.",
      new_discipline_error_repeated: "Ya tienes esta disciplina en tu perfil.",
      new_discipline_error_unavailable: "Esta disciplina no está disponible.",
      logout: "Cerrar sesión",
      trophy_missing: "¿Te falta algún trofeo?",
    },
    popups: {
      share: {
        title: "Comparte tu Shelf",
        text: "¡Hola! Echa un vistazo a mi estantería virtual en Mitali",
        subtitle: "Con el resto del mundo",
      },
      share_trophy: {
        title: "¡Hola! Mira qué trofeo que conseguido",
        text: "Mitali, tu estantería virtual",
      },
      trophies_not_found:
        "No hemos encontrado ningún trofeo tuyo. Lo volveremos a intentar más adelante automáticamente.",
      user_not_allowed:
        "No estás autorizado para crear tu cuenta. Debes inscribirte a nuestra lista de espera https://waitlist.mitali.app",
      new_trophy: "Nuevo",
      highlight_trophy: "Resaltar este trofeo",
      unhighlight_trophy: "Dejar de resaltarlo",
    },
    error_404: {
      title: "¡Oh no...",
      subtitle: "Este Shelf aún no pertenece a nadie",
      text: "Asegúrate de haber escrito correctamente el nombre de usuario. Si el problema persiste, es posible que el nombre de usuario haya sido cambiado.",
      go_back: "Volver",
    },
    ui: {
      options_updated: "Opciones actualizadas",
      copied_clipboard: "¡Copiado al portapapeles!",
      finish_tutorial: "Finalizar",
    },
    navbar: {
      members: "Miembros",
      trophies: "Trofeos",
      ranking: "Ránking",
    },
    members_list: {
      no_members: "Actualmente, no tienes ningún miembro en tu equipo.",
      no_members_visitor: "Este equipo todavía no tiene ningún miembro.",
      add_team_members: "Invita a nuevos miembros",
      trophies: "Trofeos",
      medals: "Medallas",
      invite_members_popup: {
        title: "Invita a miembros",
        subtitle: "Todas las personas que usen este enlace se unirán a tu equipo.",
        copy_link_button: "Copiar enlace",
        button_subtitle: "Este enlace durará una semana, pero puedes crear otro cuando lo necesites",
      }
    },

    members_ranking: {
      ranking_calculation: "¿Cómo calculamos este ránking?",
      ranking_calculation_explanation: "Usamos un sistema de puntos:<br />cada trofeo de oro vale 15 puntos,<br />uno de plata, 10 y uno de bronce, 5.<br />Con cada medalla otorgamos un punto.<br />Este ránking tiene una finalidad de<br />entretenimiento.<br />En Mitali creemos que uno solo tiene<br />que compararse consigo mismo.",
    },

    trophies: {
      no_trophies_no_medals: "No hay ningún trofeo o medalla de este año",
    }
  },
};

export default langs;
